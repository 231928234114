import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dataServiceBaseUrl, dataService } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const allTermSetService = createApi({
  reducerPath: "allTermSetService",
  baseQuery: fetchBaseQuery({ baseUrl: dataServiceBaseUrl }),
  tagTypes: ["allTermSet"],
  endpoints: (builder) => ({
    getAllTermSet: builder.mutation({
      query(body) {
        return {
          url: `${dataService.termSetController.getAllTermSet}?page=${body.page}&size=${body.size}&sort=${body.sort}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    getAllTermSetWithSearch: builder.mutation({
      query(body) {
        return {
          url: `${dataService.termSetController.getAllTermSetWithSearch}`,
          method: "POST",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    updateTermSet: builder.mutation({
      query(body) {
        return {
          url: `${dataService.termSetController.updateTermSet}/${body.id}`,
          method: "PUT",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    deleteTermSetById: builder.mutation({
      query(body) {
        return {
          url: `${dataService.termSetController.deleteTermSetById}/${body.id}`,
          method: "DELETE",
        };
      },
    }),
    getTermSetById: builder.mutation({
      query(body) {
        return {
          url: `${dataService.termSetController.getTermSetById}/${body.id}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
        };
      },
    }),
    saveTermSet: builder.mutation({
      query(body) {
        return {
          url: `${dataService.termSetController.saveTermSet}`,
          method: "POST",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
  }),
});
export const {
  useGetAllTermSetMutation,
  useSaveTermSetMutation,
  useDeleteTermSetByIdMutation,
  useGetTermSetByIdMutation,
  useUpdateTermSetMutation,
  useGetAllTermSetWithSearchMutation
} = allTermSetService;
