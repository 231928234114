import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface AllTermState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
  termSetsCountData: [] | null | any;
}

const initialState: AllTermState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
  termSetsCountData: null,
};

export const allTermSlice = createSlice({
  name: 'allTerm',
  initialState,
  reducers: {
    setAllTerm: (state, action) => {
      state.data = action.payload.data.content;
      state.totalRecords = action.payload.data.totalElements;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.number;
    },
    setAllTermBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setTermSetsCountData: (state, action) => {
      state.termSetsCountData = action.payload.data;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAllTerm,setAllTermBySearch, setTermSetsCountData, reset } = allTermSlice.actions;
export const getAllTermDetails = (state: RootState) => state.allTerm.data;
export const getAllTermDetailsBySearch = (state: RootState) => state.allTerm.data;
export const getTermTotalRecords=(state:RootState)=>state.allTerm.totalRecords;
export const getTermSetsCountDetails = (state: RootState) => state.allTerm.termSetsCountData;
export default allTermSlice.reducer;