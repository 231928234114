import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dataServiceBaseUrl, dataService } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const allValueChainCategoriesService = createApi({
  reducerPath: "allValueChainCategoriesService",
  baseQuery: fetchBaseQuery({ baseUrl: dataServiceBaseUrl }),
  tagTypes: ["allValueChainCategories"],
  endpoints: (builder) => ({
    getAllValueChainCategories: builder.mutation({
      query(body) {
        return {
          url: `${dataService.valueChainCategoryController.getAllValueChainCategory}?page=${body.page}&size=${body.size}&sort=${body.sort}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    getAllValueChainCategoriesWithSearch: builder.mutation({
      query(body) {
        return {
          url: `${dataService.valueChainCategoryController.getAllValueChainCategoryWithSearch}`,
          method: "POST",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    updateValueChainCategory: builder.mutation({
      query(body) {
        return {
          url: `${dataService.valueChainCategoryController.updateValueChainCategory}/${body.id}`,
          method: "PUT",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    deleteValueChainCategoryById: builder.mutation({
      query(body) {
        return {
          url: `${dataService.valueChainCategoryController.deleteValueChainCategoryById}/${body.id}`,
          method: "DELETE",
        };
      },
    }),
    getValueChainCategoryById: builder.mutation({
      query(body) {
        return {
          url: `${dataService.valueChainCategoryController.getValueChainCategoryById}/${body.id}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
        };
      },
    }),
    saveValueChainCategory: builder.mutation({
      query(body) {
        return {
          url: `${dataService.valueChainCategoryController.saveValueChainCategory}`,
          method: "POST",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
  }),
});
export const {
  useGetAllValueChainCategoriesMutation,
  useGetValueChainCategoryByIdMutation,
  useDeleteValueChainCategoryByIdMutation,
  useSaveValueChainCategoryMutation,
  useUpdateValueChainCategoryMutation,
  useGetAllValueChainCategoriesWithSearchMutation
} = allValueChainCategoriesService;
