import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface AllValueChainsState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
  valueChainCatCountData: [] | null | any;
}

const initialState: AllValueChainsState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
  valueChainCatCountData: null,
};

export const allValueChainsSlice = createSlice({
  name: 'allValueChains',
  initialState,
  reducers: {
    setAllValueChains: (state, action) => {
      state.data = action.payload.data.content;
      state.totalRecords = action.payload.data.totalElements;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.number;
    },
    setAllValueChainsBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setValueChainCatCount: (state, action) => {
      state.valueChainCatCountData = action.payload.data;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAllValueChains,setAllValueChainsBySearch, setValueChainCatCount,reset } = allValueChainsSlice.actions;
export const getAllValueChainsDetails = (state: RootState) => state.allValueChains.data;
export const getAllValueChainsDetailsBySearch = (state: RootState) => state.allValueChains.data;
export const getValueChainTotalRecords=(state:RootState)=>state.allValueChains.totalRecords;
export const getValueChainCategoryCountDetails = (state: RootState) => state.allValueChains.valueChainCatCountData;
export default allValueChainsSlice.reducer;