import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { Select, Switch, Table } from "antd";
import { useAppSelector } from "../redux-toolkit/hooks";
import { useDispatch } from "react-redux";
import { ACTION_TYPE } from "../constant/reportType";
import { format } from "date-fns";
import { BsSearch } from "react-icons/bs";
import { STATE_COLUMN, COUNTRY_COLUMN } from "../constant/AppConstant";
import {
  useDeleteCountryMutation,
  useGetAllCountryMutation,
  useGetAllCountryWithSearchMutation,
} from "../redux-toolkit/services/countryService";
import {
  useDeleteStateMutation,
  useGetAllStatesMutation,
  useGetAllStatesWithSearchMutation,
  useGetStateMutation,
  useUpdateStateMutation,
} from "../redux-toolkit/services/stateService";
import {
  getAllcountryDetail,
  setcountry,
} from "../redux-toolkit/slices/countrySlice";
import { alertMessage } from "../utils/AlertService";
import editIcon from "../assets/icons/editicon.svg";
import Loading from "./Loading";
import {
  getAllStatesTotalRecords,
  getAllStatesWithPaginationDetails,
  getFindStateByCountryNameDetail,
  setAllStatesWithPagination,
  setStates,
} from "../redux-toolkit/slices/statesSlice";
import CommonData from "../pages/reports/CommonData";
import { getLocalStorageData } from "../utils/LocalStorageService";

const { Option } = Select;
type Props = {};
const Common = (props: Props) => {
  const params = useParams();
  const token: any = getLocalStorageData("localUser").token;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // States
  const [show, setShow] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [currentpage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [reportType, setReportType] = useState(params.reportType) as any;
  const [reportName, setReportName]: any = useState();
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
  const [selectedRow, setSelectedRow]: any = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  const [call, setCall] = useState("N");
  const [stateData, setStateData] = useState([]);

  //columns
  const [countryColumn, setCountryColumn]: any = useState(COUNTRY_COLUMN);
  const [stateColumn, setStateColumn]: any = useState(STATE_COLUMN);

  //datasource
  const [countryDataSource, setCountryDataSource]: any = useState([]);
  const [stateDataSource, setStateDataSource]: any = useState([]);

  //get mutation
  const [getAllCountry, getAllCountryResponse] = useGetAllCountryMutation();
  const [getAllState, getAllStateResponse] = useGetAllStatesMutation();

  //delete mutation
  const [deleteCountry, deleteCountryResponse] = useDeleteCountryMutation();
  const [deleteState, deleteStateResponse] = useDeleteStateMutation();

  const [updateState, updateStateResponse] = useUpdateStateMutation();

  //selector
  const allCountry: any = useAppSelector(getAllcountryDetail);
  const allState: any = useAppSelector(getAllStatesWithPaginationDetails);
  const allStatesTotalRecords: any = useAppSelector(getAllStatesTotalRecords);

  //search
  const [getAllStatesWithSearch, getAllStatesWithSearchResponse] =
    useGetAllStatesWithSearchMutation();
  const [getAllCountriesWithSearch, getAllCountriesWithSearchResponse] =
    useGetAllCountryWithSearchMutation();

  useEffect(() => {
    if (params.reportType === "State") {
      setReportName("state");
      setIsSelect(true);
      getAllCountry({});
      getAllState({ page: currentpage - 1, offset: offset, orderBy: "asc" });
    } else if (params.reportType === "Country") {
      setReportName("country");
      setIsSelect(false);
      getAllCountry({});
    }
  }, []);

  useEffect(() => {
    if (call === "Y") {
      if (params.reportType === "State") {
        setReportName("state");
        setIsSelect(true);
        getAllState({ page: currentpage - 1, offset: offset, orderBy: "asc" });
        setCall("N");
      } else if (params.reportType === "Country") {
        setReportName("country");
        setIsSelect(false);
        getAllCountry({});
        setCall("N");
      }
    }
  }, [call]);

  useEffect(() => {
    if (allCountry) {
      setCountryDataSource(allCountry);
    }
  }, [allCountry]);

  useEffect(() => {
    if (allState) {
      setStateDataSource(allState);
    }
  }, [allState]);

  useEffect(() => {
    if (getAllCountryResponse.isSuccess) {
      dispatch(setcountry(getAllCountryResponse));
    } else if (getAllCountryResponse.isError) {
      let response: any = getAllCountryResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllCountryResponse.isSuccess, getAllCountryResponse.isError]);

  useEffect(() => {
    if (getAllStateResponse.isSuccess) {
      dispatch(setAllStatesWithPagination(getAllStateResponse));
    } else if (getAllStateResponse.isError) {
      let response: any = getAllStateResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllStateResponse.isSuccess, getAllStateResponse.isError]);

  //delete..........
  const onDeleteData = (record: any) => {
    if (reportName === "country") {
      deleteCountry({
        token: token,
        id: record.id,
      });
    } else if (reportName === "state") {
      deleteState({
        token: token,
        id: record.id,
      });
    }
  };

  useEffect(() => {
    if (updateStateResponse.isSuccess) {
      alertMessage("State updated successfully", "success");
      setCall("Y");
    } else if (updateStateResponse.isError) {
      let response: any = updateStateResponse?.error;
      alertMessage(response, "error");
    }
  }, [updateStateResponse.isSuccess, updateStateResponse.isError]);

  useEffect(() => {
    if (deleteCountryResponse.isSuccess) {
      alertMessage("Country status updated successfully", "success");
      setCall("Y");
    } else if (deleteCountryResponse.isError) {
      let response: any = deleteCountryResponse.error;
      alertMessage(response, "error");
    }
  }, [deleteCountryResponse.isSuccess, deleteCountryResponse.isError]);

  useEffect(() => {
    if (deleteStateResponse.isSuccess) {
      alertMessage("State status updated successfully", "success");
      setCall("Y");
    } else if (deleteStateResponse.isError) {
      let response: any = deleteStateResponse.error;
      alertMessage(response, "error");
    }
  }, [deleteStateResponse.isSuccess, deleteStateResponse.isError]);

  const handleOpenForm = (record: any) => {
    setShow(true);
    setActionType(ACTION_TYPE.UPDATE_ACTION);
    setSelectedRow(record);
  };
  useEffect(() => {
    const temp: any = [];
    const tempAction: any = {
      title: "Action",
      dataIndex: "action",
      key: "name",
      render: (index: any, record: any) => {
        return (
          // for delete switch button
          <div className="d-flex">
            <div className="px-2 pe-auto">
              <Switch
                checked={
                  record.status === true ||
                    record.enabled === true ||
                    record.isEnabled === true
                    ? true
                    : false
                }
                onClick={() => {
                  onDeleteData(record);
                }}
              />
            </div>
            {record.status === true ||
              record.enabled === true ||
              record.isEnabled === true ? (
              <div
                className="px-2 pe-auto"
                onClick={() => {
                  handleOpenForm(record);
                }}
              >
                <img src={editIcon} alt="update" />
              </div>
            ) : null}
          </div>
        );
      },
    };
    if (reportName === "state" && stateColumn) {
      stateColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setStateColumn(temp);
    } else if (reportName === "country" && countryColumn) {
      countryColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setCountryColumn(temp);
    }
  }, [reportName]);

  const paginationChange = (page: number, offset: number) => {
    if (reportName === "state") {
      getAllState({
        page: page - 1,
        offset: offset,
        orderBy: "asc",
      });
    }
    setCurrentPage(page);
    setOffset(offset);
  };

  //   useEffect(() => {
  //     let ob: any = {
  //       offset: offset,
  //       pageNumber: currentpage - 1,
  //     };
  //     if (token) {
  //       let columnReportType = COLUMN_REPORTYPE.COUNTRY;
  //       let resourceIdForReport = RESOURCE_ID.SQL_VMS;
  //       switch (params.reportType?.toUpperCase()) {
  //         case "Country".toUpperCase():
  //           columnReportType = COLUMN_REPORTYPE.COUNTRY;
  //           resourceIdForReport = RESOURCE_ID.SQL_VMS;
  //           break;
  //         case "State".toUpperCase():
  //           columnReportType = COLUMN_REPORTYPE.STATE;
  //           resourceIdForReport = RESOURCE_ID.SQL_VMS;
  //           break;
  //         default:
  //       }
  //       setReportType(columnReportType);
  //       ob.reportType = columnReportType;
  //     }
  //   }, []);

  const handleShow = () => setShow(true);

  // navigate............
  const handleBack = () => {
    navigate("/reports");
  };

  const buildStateData = (data: any) => {
    let temp: any = [];
    data.forEach((element: any) => {
      // let payload =
      temp.push({
        id: element.id,
        name: element.name,
        countryName: element.country.name === null ? "-" : element.country.name,
        countryId: element.countryId === null ? "-" : element.countryId,
        status: element.status,
      });
    });
    setStateData(temp);
  };

  useEffect(() => {
    if (stateDataSource) {
      buildStateData(stateDataSource);
    }
  }, [stateDataSource]);

  const handleSearch = (event: any) => {
    // if ((event.target.value).length >= 3 || event.target.value == 0) {
      if (reportName === "state") {
        getAllStatesWithSearch({
          token: token,
          payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
        });
      } else if (reportName === "country") {
        getAllCountriesWithSearch({
          token: token,
          payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
        });
      }
    // }
  };

  useEffect(() => {
    if (getAllStatesWithSearchResponse.isSuccess) {
      dispatch(setAllStatesWithPagination(getAllStatesWithSearchResponse));
    } else if (getAllStatesWithSearchResponse.isError) {
      let response: any = getAllStatesWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [
    getAllStatesWithSearchResponse.isSuccess,
    getAllStatesWithSearchResponse.isError,
  ]);

  useEffect(() => {
    if (getAllCountriesWithSearchResponse.isSuccess) {
      dispatch(setcountry(getAllCountriesWithSearchResponse.data));
    } else if (getAllCountriesWithSearchResponse.isError) {
      let response: any = getAllCountriesWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [
    getAllCountriesWithSearchResponse.isSuccess,
    getAllCountriesWithSearchResponse.isError,
  ]);

  return (
    <div className="mt-2 overflow-y-auto">
      {/* navigate */}
      <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
        <div className="d-flex align-items-center w-100 px-2">
          <h5
            className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
            style={{ fontWeight: "700" }}
          >
            {params.reportType}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
          <div className="input-group px-2 mt-1">
            <Form.Control
              type="search"
              placeholder="Search"
              className="border-0 bg-cm-light-primary1"
              aria-label="Search"
              onChange={handleSearch}
            />
            <div className="input-group-append bg-cm-light-primary1 p-1 rounded-end">
              <span className="input-group-text bg-cm-light-primary1 border-0">
                {/* <img src={gear} alt="" /> */}
                <BsSearch className=" bg-cm-light-primary1 border-cm-light-primary1" />
              </span>
            </div>
          </div>
          <div onClick={handleShow} className="d-flex px-2 align-items-center">
            <Button
              variant="primary"
              className="d-flex align-items-center me-0 mt-1"
              onClick={() => setActionType(ACTION_TYPE.ADD_ACTION)}
            >
              <BiPlus />
              Add
            </Button>
          </div>
          {show && (
            <CommonData
              show={show}
              setShow={setShow}
              actionType={actionType}
              getAllReportAsync={() => null}
              updateAllReportAsync={({ }) => null}
              reportType={reportType}
              reportName={params.reportType}
              reportNameCode={reportName}
              isSelect={isSelect}
              data={selectedRow}
              call={call}
              setCall={setCall}
            />
          )}
        </div>
      </div>

      <div className="d-flex ">
        <div className="primary mt-2 mt-md-0 d-flex justify-content-md-start w-100 mx-2">
          <Button
            variant="primary"
            className="d-flex align-items-center me-0"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        {getAllCountryResponse.isLoading ||
          getAllStateResponse.isLoading ||
          getAllCountriesWithSearchResponse.isLoading ||
          getAllStatesWithSearchResponse.isLoading ? (
          <Loading />
        ) : (
          ""
        )}
      </div>
      <div className="my-2 w-100 overflow-x-auto rounded-3">
        <Table
          bordered={true}
          rowKey={"id"}
          pagination={
            reportName === "state"
              ? {
                total: allStatesTotalRecords && allStatesTotalRecords,
                responsive: true,
                current: currentpage,
                showSizeChanger: true,
                pageSizeOptions: [15, 20, 30],
                onChange: (page, offset) => {
                  paginationChange(page, offset);
                },
                pageSize: offset,
              }
              : {
                responsive: true,
              }
          }
          columns={
            reportName === "state"
              ? stateColumn
              : reportName === "country"
                ? countryColumn
                : ""
          }
          dataSource={
            reportName === "state"
              ? stateData
              : reportName === "country"
                ? countryDataSource
                : ""
          }
        />
      </div>
    </div>
  );
};
export default Common;
