import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { dataServiceBaseUrl, dataService, userManagementBaseUrl, userManagement } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const auditLogService = createApi({
    reducerPath: 'auditLogService',
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ['AuditLog'],
    endpoints: (builder) => ({
        getAuditLog: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.auditLogController.getAuditLog}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),       
        saveAuditLog: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.auditLogController.saveAuditLog}`,
                    method: 'POST',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
    })
  });
  export const {
   useGetAuditLogMutation,
   useSaveAuditLogMutation,
  } = auditLogService;