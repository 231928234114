import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dataServiceBaseUrl, dataService } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const allDataPipelinesCategoriesService = createApi({
  reducerPath: "allDataPipelinesCategoriesService",
  baseQuery: fetchBaseQuery({ baseUrl: dataServiceBaseUrl }),
  tagTypes: ["DataPipelinesCategories"],
  endpoints: (builder) => ({
    getAllDataPipelinesCategories: builder.mutation({
      query(body) {
        return {
          url: `${dataService.dataPipelineCategoryController.getAllDataPipelinesCategories}?page=${body.page}&size=${body.size}&sort=${body.sort}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    getAllDataPipelinesCategoriesWithSearch: builder.mutation({
      query(body) {
        return {
          url: `${dataService.dataPipelineCategoryController.getAllDataPipelinesCategoriesWithSearch}`,
          method: "POST",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    getAllDataPipelinesCategoriesById: builder.mutation({
      query(body) {
        return {
          url: `${dataService.dataPipelineCategoryController.getDataPipelineCategoryById}/${body.id}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
          // body: body.payload,
        };
      },
    }),
    saveDataPipelineCategory: builder.mutation({
      query(body) {
        return {
          url: `${dataService.dataPipelineCategoryController.saveDataPipelineCategory}`,
          method: "POST",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    updateDataPipelineCategory: builder.mutation({
      query(body) {
        return {
          url: `${dataService.dataPipelineCategoryController.updateDataPipelineCategory}/${body.payload.id}`,
          method: "PUT",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    deleteDataPipelineCategoryById: builder.mutation({
      query(body) {
        return {
          url: `${dataService.dataPipelineCategoryController.deleteDataPipelineCategoryById}/${body.id}`,
          method: "DELETE",
          // headers: { Authorization: `Bearer ${body.token}`},
        };
      },
    }),
  }),
});
export const {
  useGetAllDataPipelinesCategoriesMutation,
  useSaveDataPipelineCategoryMutation,
  useUpdateDataPipelineCategoryMutation,
  useDeleteDataPipelineCategoryByIdMutation,
  useGetAllDataPipelinesCategoriesByIdMutation,
  useGetAllDataPipelinesCategoriesWithSearchMutation
} = allDataPipelinesCategoriesService;
