import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface cityState {
    data: {} | null | any;
}

const initialState: cityState = {
    data: null,  
};

export const citySlice = createSlice({
    name: 'city',
    initialState,
    reducers: {
        setCity: (state, action) => {
            state.data = action.payload;
        },
        addNewCity: (state: any, action) => {
            state.data = [action.payload, ...state.data];
        },
        reset: (state) => {
            state = initialState;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setCity,addNewCity, reset } = citySlice.actions;
export const getFindCityByStateIdDetail = (state: RootState) => state.city.data;
export default citySlice.reducer;

