import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface AllDataPipelineState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
  dataPipelineCatCountData: [] | null | any;
}
const initialState: AllDataPipelineState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
  dataPipelineCatCountData: null,
};
export const   allDataPipelinesSlice = createSlice({
  name: 'allDataPipelines',
  initialState,
  reducers: {
    setAllDataPipelines: (state, action) => {
      state.data = action.payload.data.content;
      state.totalRecords = action.payload.data.totalElements;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.number;
    },
    setAllDataPipelinesBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setDataPipelineCatCountData: (state, action) => {
      state.dataPipelineCatCountData = action.payload.data;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllDataPipelines,setAllDataPipelinesBySearch, setDataPipelineCatCountData,reset } = allDataPipelinesSlice.actions;
export const getDataPipelinesDetails = (state: RootState) => state.allDataPipelines.data;
export const getDataPipelinesDetailsBySearch = (state: RootState) => state.allDataPipelines.data;
export const getDataPipelineTotalRecords=(state:RootState)=>state.allDataPipelines.totalRecords;
export const getDataPipelineCategoryCountDetails = (state: RootState) => state.allDataPipelines.dataPipelineCatCountData;
export default allDataPipelinesSlice.reducer;