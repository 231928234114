import { CircularProgress } from '@mui/material';
import React from 'react';

type Props = {};

const Loading = (props: Props) => {
  return (
    <div
      style={{
        background: '#0000009f',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 1250,
      }}
    >
      <CircularProgress size={100} />
    </div>
  );
};

export default Loading;
