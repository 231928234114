import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userManagementBaseUrl, userManagement} from "../endpoints";
// Define a service using a base URL and expected endpoints
export const permissionService = createApi({
    reducerPath: "permissionService",
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ["permission"],
    endpoints: (builder) => ({
        getAllPermissions: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.permissionController.getAllPermissions}`,
                    method: "GET",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
    }),
});
export const { useGetAllPermissionsMutation } = permissionService;