import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dataServiceBaseUrl, dataService } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const allTermService = createApi({
  reducerPath: "allTermService",
  baseQuery: fetchBaseQuery({ baseUrl: dataServiceBaseUrl }),
  tagTypes: ["allTerm"],
  endpoints: (builder) => ({
    getAllTerm: builder.mutation({
      query(body) {
        return {
          url: `${dataService.termController.getAllTerm}?page=${body.page}&size=${body.size}&sort=${body.sort}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    getAllTermWithSearch: builder.mutation({
      query(body) {
        return {
          url: `${dataService.termController.getAllTermWithSearch}`,
          method: "POST",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    updateTerm: builder.mutation({
      query(body) {
        return {
          url: `${dataService.termController.updateTerm}/${body.id}`,
          method: "PUT",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    deleteTermById: builder.mutation({
      query(body) {
        return {
          url: `${dataService.termController.deleteTermById}/${body.id}`,
          method: "DELETE",
          // headers: { Authorization: `Bearer ${body.token}`},
        };
      },
    }),
    getTermById: builder.mutation({
      query(body) {
        return {
          url: `${dataService.termController.getTermById}/${body.id}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
        };
      },
    }),
    saveTerm: builder.mutation({
      query(body) {
        return {
          url: `${dataService.termController.saveTerm}`,
          method: "POST",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
     getTermsTermsetIdById: builder.mutation({
      query(body) {
        return {
          url: `${dataService.termController.getTermById}/${body.id}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
        };
      },
    }),
     getTermsStringlistTermsetIdById: builder.mutation({
      query(body) {
        return {
          url: `${dataService.termController.getTermById}/${body.id}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
        };
      },
    }),
    getCountAndTermSetName: builder.mutation({
      query(body) {
          return {
              url: `${dataService.termController.getCountAndTermSetName}`,
              method: "GET",
              // headers: { Authorization: `Bearer ${body.token}`},
          };
      },
  }),
  }),
});
export const {
  useGetAllTermMutation,
  useSaveTermMutation,
  useDeleteTermByIdMutation,
  useGetTermByIdMutation,
  useUpdateTermMutation,
  useGetTermsTermsetIdByIdMutation,
  useGetTermsStringlistTermsetIdByIdMutation,
  useGetAllTermWithSearchMutation,
  useGetCountAndTermSetNameMutation
} = allTermService;
