import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Formik } from "formik";
import { ACTION_TYPE } from "../../constant/reportType";
import { useDispatch } from "react-redux";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { alertMessage } from "../../utils/AlertService";
import * as Yup from "yup";
import { useAppSelector } from "../../redux-toolkit/hooks";
import { useGetAllCitiesMutation } from "../../redux-toolkit/services/cityService";
import {
  useGetAllCountryMutation,
  useGetCountryByIdMutation,
  useSaveCountryMutation,
  useUpdateCountryMutation,
} from "../../redux-toolkit/services/countryService";
import {
  useGetStateByIdMutation,
  useGetStateMutation,
  useSaveStateMutation,
  useUpdateStateMutation,
} from "../../redux-toolkit/services/stateService";
import {
  getFindStateByCountryNameDetail,
  setStates,
} from "../../redux-toolkit/slices/statesSlice";
import {
  getAllcountryDetail,
  setcountry,
} from "../../redux-toolkit/slices/countrySlice";
import { getFindCityByStateIdDetail } from "../../redux-toolkit/slices/citySlice";
import Loading from "../../component/Loading";

type Props = {
  setShow: any;
  show: any;
  actionType: ACTION_TYPE;
  data?: any;
  setReportData?: any;
  getAllReportAsync: () => void;
  updateAllReportAsync: (values: any) => void;
  reportType?: any;
  reportName?: string;
  isSelect?: boolean;
  reportNameCode?: string;
  setCall?: any;
  call?: any;
};

// validation...........
const formSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  select: Yup.string().required("Please select"),
});

const userSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  mobileNo: Yup.string().required("Please enter mobile number"),
  email: Yup.string().email("Invalid email").required("Please enter email"),
});

const CommonData = (props: Props) => {
  const token: any = getLocalStorageData("localUser").token;
  const dispatch = useDispatch();

  //selector
  const allCountries: any = useAppSelector(getAllcountryDetail);
  const allCities: any = useAppSelector(getFindCityByStateIdDetail);
  const allStates: any = useAppSelector(getFindStateByCountryNameDetail);

  //state
  const [getByIdData, setGetByIdData]: any = useState();
  const [country, setCountry]: any = useState();
  const [city, setCity]: any = useState();
  const [state, setState]: any = useState();
  const [selectedStateId, setSelectedStateId]: any = useState();
  const [stateName, setStateName]: any = useState();

  //getData
  const [getCountry, getCountryResponse] = useGetAllCountryMutation();
  const [getState, getStateResponse] = useGetStateMutation();
  const [getCities, getCitiesResponse] = useGetAllCitiesMutation();

  //save
  const [saveCountry, saveCountryResponse] = useSaveCountryMutation();
  const [saveState, saveStateResponse] = useSaveStateMutation();

  //update
  const [updateCountry, updateCountryResponse] = useUpdateCountryMutation();
  const [updateState, updateStateResponse] = useUpdateStateMutation();

  //getById mutation
  const [getCountryById, getCountryByIdResponse] = useGetCountryByIdMutation();
  const [getStateById, getStateByIdResponse] = useGetStateByIdMutation();

  const [selectedValue, setSelectedValue] = useState(
    props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === "state"
      ? props.data.countryId
      : ""
  );

  const [selectedCountry, setSelectedCountry] = useState(
    (props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === "user") ||
      props.reportNameCode === "dataRecord"
      ? props.data.countryId
      : ""
  );
  const [selectedState, setSelectedState] = useState(
    (props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === "user") ||
      props.reportNameCode === "dataRecord"
      ? props.data.stateId
      : ""
  );
  const [selectedCity, setSelectedCity] = useState(
    props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === "user"
      ? props.data.cityId
      : ""
  );

  // useEffect(() => {
  //   if (props.reportNameCode === "country") {
  //     getCountry({ token: token });
  //   }
  // }, []);

  // useEffect(() => {
  //   if (props.reportNameCode === "state") {
  //     getState({});
  //   }
  // }, []);

  useEffect(() => {
    if (getCountryResponse.isSuccess) {
      dispatch(setcountry(getCountryResponse));
    } else if (getCountryResponse.isError) {
      let response: any = getCountryResponse.error;
      alertMessage(response, "error");
    }
  }, [getCountryResponse.isSuccess, getCountryResponse.isError]);

  useEffect(() => {
    if (getStateResponse.isSuccess) {
      dispatch(setStates(getStateResponse));
    } else if (getStateResponse.isError) {
      let response: any = getStateResponse.error;
      alertMessage(response, "error");
    }
  }, [getStateResponse.isSuccess, getStateResponse.isError]);

  useEffect(() => {
    if (getCitiesResponse.isSuccess) {
      dispatch(setCity(getCitiesResponse));
    } else if (getCitiesResponse.isError) {
      let response: any = getCitiesResponse.error;
      alertMessage(response, "error");
    }
  }, [getCitiesResponse.isSuccess, getCitiesResponse.isError]);

  useEffect(() => {
    if (props.reportNameCode === "state") {
      if (allStates) {
        setStates(allStates);
      }
    }
  }, [allStates]);

  useEffect(() => {
    if (props.reportNameCode === "state") {
      if (
        props.actionType === ACTION_TYPE.UPDATE_ACTION ||
        props.actionType === ACTION_TYPE.ADD_ACTION
      ) {
        getCountry({});
      }
    }
  }, []);

  // handleSubmit...........
  const handleSubmit = (values: any) => {
    if (props.actionType === ACTION_TYPE.ADD_ACTION) {
      if (props.reportNameCode === "country") {
        saveCountry({
          token: token,
          payload: {
            id: 0,
            name: values.name,
            status: true,
          },
        });
      } else if (props.reportNameCode === "state") {
        saveState({
          token: token,
          payload: {
            // id: 0,
            countryId: parseInt(selectedValue),
            // countryName: country,
            name: values.name,
            status: true,
          },
        });
      }
    } else if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
      if (props.reportNameCode === "country") {
        updateCountry({
          token: token,
          payload: {
            id: props.data.id,
            name: values.name,
            status: true,
          },
        });
      } else if (props.reportNameCode === "state") {
        updateState({
          token: token,
          payload: {
            id: props?.data?.id,
            countryId: parseInt(selectedValue),
            countryName: country,
            name: values.name,
            status: true,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
      if (props.reportNameCode === "country") {
        getCountryById({
          token: token,
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === "state") {
        getStateById({
          token: token,
          id: props?.data?.id,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (getCountryByIdResponse.isSuccess) {
      setGetByIdData(getCountryByIdResponse.data);
    } else if (getCountryByIdResponse.isError) {
      let response: any = getCountryByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [getCountryByIdResponse.isSuccess, getCountryByIdResponse.isError]);

  useEffect(() => {
    if (getStateByIdResponse.isSuccess) {
      setGetByIdData(getStateByIdResponse.data);
    } else if (getStateByIdResponse.isError) {
      let response: any = getStateByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [getStateByIdResponse.isSuccess, getStateByIdResponse.isError]);

  const handleClose = () => props.setShow(false);

  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
    const countryName = allCountries.filter(
      (country: any) => country.id == event.target.value
    );
    setCountry(countryName[0].name);
  };

  useEffect(() => {
    if (saveCountryResponse.isSuccess) {
      alertMessage("Country added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveCountryResponse.isError) {
      let response: any = saveCountryResponse?.error;
      alertMessage(response, "error");
    }
  }, [saveCountryResponse.isSuccess, saveCountryResponse.isError]);

  useEffect(() => {
    if (updateCountryResponse.isSuccess) {
      alertMessage("Country updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateCountryResponse.isError) {
      let response: any = updateCountryResponse?.error;
      alertMessage(response, "error");
    }
  }, [updateCountryResponse.isSuccess, updateCountryResponse.isError]);

  //user
  useEffect(() => {
    if (saveStateResponse.isSuccess) {
      alertMessage("State added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveStateResponse.isError) {
      let response: any = saveStateResponse?.error;
      let msg: any = response.data?.serviceErrors[0]?.msg;
      alertMessage(msg, "error");
    }
  }, [saveStateResponse.isSuccess, saveStateResponse.isError]);

  useEffect(() => {
    if (updateStateResponse.isSuccess) {
      alertMessage("State updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateStateResponse.isError) {
      let response: any = updateStateResponse?.error;
      let msg: any = response.data?.serviceErrors[0]?.msg;
      alertMessage(msg, "error");
    }
  }, [updateStateResponse.isSuccess, updateStateResponse.isError]);

  return (
    <div>
      {/* <!-- Modal --> */}
      <Modal
        show={props.show}
        onHide={handleClose}
        className=""
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header>
          {props.reportNameCode === "dataRecord" ? (
            <Modal.Title
              className="fw-bold text-primary"
              style={{ fontSize: "1rem" }}
            >
              Web Search
            </Modal.Title>
          ) : (
            <Modal.Title
              className="fw-bold text-primary"
              style={{ fontSize: "1rem" }}
            >
              {props.actionType === ACTION_TYPE.ADD_ACTION ? "Add" : "Update"}{" "}
              {props.reportName}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {getCountryResponse.isLoading ||
            getStateResponse.isLoading ||
            getCitiesResponse.isLoading ||
            getCountryByIdResponse.isLoading ||
            getStateByIdResponse.isLoading ||
            saveCountryResponse.isLoading ||
            updateCountryResponse.isLoading ||
            saveStateResponse.isLoading ||
            updateStateResponse.isLoading ? (
            <Loading />
          ) : (
            ""
          )}
          <Formik
            // validationSchema={formSchema}
            enableReinitialize={true}
            initialValues={{
              name:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.name
                  : "",
              select:
                props.actionType === ACTION_TYPE.UPDATE_ACTION &&
                  props.reportNameCode === "state"
                  ? getByIdData && getByIdData.countryId
                  : "",
            }}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange }) => (
              <Form>
                <>
                  {props.isSelect && (
                    <p>
                      <div className="w-100">
                        <div className="d-flex">
                          <div> Select Country </div>
                          <div style={{ color: "red" }}>*</div>
                        </div>
                        <div className="pe-2">
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label=".form-select-sm example"
                            onChange={handleSelectChange}
                            // onChange={(event) => {
                            //   const { selectedIndex } = event.target;
                            //   handleSelectChange(options[selectedIndex]);
                            // }}
                            name="select"
                            value={selectedValue}
                            required
                          >
                            <option value="">Select Country </option>
                            {allCountries &&
                              allCountries.map((itemType: any, index: any) => {
                                return (
                                  <option
                                    key={index.toString()}
                                    // value={itemType.id+ "-"+itemType.name}
                                    value={itemType.id}
                                  >
                                    {itemType.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </p>
                  )}
                  <p>
                    <div className="d-flex">
                      <div> {props.reportName} Name</div>
                      <div style={{ color: "red" }}>*</div>
                    </div>
                    <input
                      className="form-control form-control-sm w-100"
                      type="text"
                      aria-label=".form-control-sm example"
                      onChange={handleChange}
                      value={values.name}
                      name="name"
                      required
                    />
                  </p>
                </>
                <div className="d-flex flex-row justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                  >
                    {props.actionType === ACTION_TYPE.ADD_ACTION
                      ? "Save"
                      : "Update"}
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary btn-sm btn-block mt-3 mx-2 btn-width"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default CommonData;
