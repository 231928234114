import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface GdprState {
  data: [] | null | any;
  aiRegComplianceTestdata: [] | null | any;
  fileName: string | null;
  aiRegComplianceTestFileName: string | null;
  dataPrivacyPenaltyPrediction: [] | null | any;
  sensitiveBiasDataIdentification: [] | null | any;
}
const initialState: GdprState = {
  data: null,
  aiRegComplianceTestdata: null,
  fileName: null,
  aiRegComplianceTestFileName: null,
  dataPrivacyPenaltyPrediction: null,
  sensitiveBiasDataIdentification: null
};
export const   gdprSlice = createSlice({
  name: 'gdpr',
  initialState,
  reducers: {
    setGdpr: (state, action) => {
      state.data = action.payload.data.data;
      // state.fileName = action.payload.data.data[0][0].fileName;
    },
    setAiRegComplianceTest: (state, action) => {
      state.aiRegComplianceTestdata = action.payload.data.data;
      // state.aiRegComplianceTestFileName = action.payload.data.data[0][0].fileName;
    },
    setDataPrivacyPenaltyPrediction: (state, action) => {
      state.dataPrivacyPenaltyPrediction = action.payload.data.Countries;
    },
    setSensitiveBiasDataIdentification: (state, action) => {
      state.sensitiveBiasDataIdentification = action.payload.data;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setGdpr, setAiRegComplianceTest, setDataPrivacyPenaltyPrediction, setSensitiveBiasDataIdentification,reset } = gdprSlice.actions;
export const getGdprDetails = (state: RootState) => state.gdpr.data;
// export const getGdprFileName = (state: RootState) => state.gdpr.fileName;
export const getAiRegComplianceTestDetails = (state: RootState) => state.gdpr.aiRegComplianceTestdata;
// export const getAiRegComplianceTestFileName = (state: RootState) => state.gdpr.aiRegComplianceTestFileName;
export const getDataPrivacyPenaltyPredictionDetails = (state: RootState) => state.gdpr.dataPrivacyPenaltyPrediction;
export const getSensitiveBiasDataIdentificationDetails = (state: RootState) => state.gdpr.sensitiveBiasDataIdentification;
export default gdprSlice.reducer;