import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { webUI, webUIBaseUrl } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const textMatcherService = createApi({
  reducerPath: "textMatcherService",
  baseQuery: fetchBaseQuery({ baseUrl: webUIBaseUrl }),
  tagTypes: ["TextMatcher"],
  endpoints: (builder) => ({
    termComparisonAnalysis: builder.mutation({
      query(body) {
        return {
          url: `${webUI.textMatcherController.termComparisonAnalysis}`,
          method: 'POST',
          body: body.file,
        }
      }
    }),
  }),
});
export const {
 useTermComparisonAnalysisMutation,
} = textMatcherService;
