import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { dataServiceBaseUrl, dataService } from '../endpoints';

// Define a service using a base URL and expected endpoints

  export const allValueChainsService = createApi({
    reducerPath: 'allValueChainsService',
    baseQuery: fetchBaseQuery({ baseUrl: dataServiceBaseUrl }),
    tagTypes: ['allValueChains'],
    endpoints: (builder) => ({
        getAllValueChains: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.valueChainsController.getAllValueChains}?page=${body.page}&size=${body.size}&sort=${body.sort}`,
                    method: 'GET',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        getAllValueChainsWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.valueChainsController.getAllValueChainsWithSearch}`,
                    method: 'POST',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        getAllValueChainsById: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.valueChainsController.getValueChainsById}/${body.id}`,
                    method: "GET",
                    // headers: { Authorization: `Bearer ${body.token}`},
                };
            },
        }),
        saveValueChain: builder.mutation({
            query(body){
                return {
                    url: `${dataService.valueChainsController.saveValueChain}`,
                    method: 'POST',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateValueChain: builder.mutation({
            query(body){
                return {
                    url: `${dataService.valueChainsController.updateValueChain}/${body.payload.id}`,
                    method: 'PUT',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteValueChainById: builder.mutation({
            query(body){
                return {
                    url: `${dataService.valueChainsController.deleteValueChainById}/${body.id}`,
                    method: 'DELETE',
                    // headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getValueChainCategoryCountName: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.valueChainsController.getValueChainCategoryCountName}`,
                    method: "GET",
                    // headers: { Authorization: `Bearer ${body.token}`},
                };
            },
        }),
    })
  });

  export const {
   useGetAllValueChainsMutation,
   useSaveValueChainMutation,
   useUpdateValueChainMutation,
   useDeleteValueChainByIdMutation,
   useGetAllValueChainsByIdMutation,
   useGetAllValueChainsWithSearchMutation,
   useGetValueChainCategoryCountNameMutation
  } = allValueChainsService;