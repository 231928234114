import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Formik } from "formik";
import { REPORT_NAME } from "../../constant/reportType";
import { useDispatch } from "react-redux";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { alertMessage } from "../../utils/AlertService";
import { useDataPrivacyPenaltyPredictionMutation, useDataSetBiasTestMutation, useFairnessMetricsForMachineLearPredMutation, useRegulationPredictionMutation } from "../../redux-toolkit/services/fastApiService";
import Loading from "../../component/Loading";
import * as Yup from "yup";
import { setDataSetBiasTest, setFairnessMetrics, setRegulationPrediction } from "../../redux-toolkit/slices/fastApiSlice";
import { setDataPrivacyPenaltyPrediction } from "../../redux-toolkit/slices/gdprSlice";
import axios from "axios";
import { fastApi, fastApiBaseUrl } from "../../redux-toolkit/endpoints";

type Props = {
  setShow: any;
  show: any;
  reportName?: string;
  reportNameCode?: string;
  setDataPrivacyPenaltyPredDatasource?: any;
  setFileName: any;
};

const PredictionModelsData = (props: Props) => {
  // validation...........
  const formSchema = Yup.object().shape({
    annualUSD: Yup.string().required("Please enter Annual USD!"),
    numViolations: Yup.string().required("Please enter Num Violations!"),

  });
  const token: any = getLocalStorageData("localUser").token;
  const selectedReportNameL: any = localStorage?.getItem('selectedReportName');
  const dispatch = useDispatch();

  //state
  const [file, setFile]: any = useState<File | null>(null);

  //mutation
  const [getDataPrivacyPenaltyPrediction, dataPrivacyPenaltyPredictionResponse]: any = useDataPrivacyPenaltyPredictionMutation();
  const [regulationPrediction, regulationPredictionResponse] = useRegulationPredictionMutation();

  // handleSubmit...........
  const handleSubmit = async (values: any) => {

    const formData = new FormData();
    if (props.reportNameCode === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION) {

      formData.append('annual_usd', values.annualUSD);
      formData.append('num_violations', values.numViolations);

      getDataPrivacyPenaltyPrediction({
        payload: formData
      });

    } else if (props.reportNameCode === REPORT_NAME.REGULATION_PREDICTION) {

      const population = parseInt(values.totalPopulation);
      const infected = parseInt(values.intialNoOfIncidents);
      const beta = parseFloat(values.transmissionRate);
      const gamma = parseFloat(values.incidentRecoveryRate);
      const days = parseInt(values.numberOfDaysToSimulate);
      const description = values.description;

      axios.get(`${fastApiBaseUrl}${fastApi.generate_graph}?population=${population}&infected=${infected}&beta=${beta}&gamma=${gamma}&days=${days}&description=${description}`, {
        responseType: 'arraybuffer',
      }).then(async (response: any) => {

        const url = window.URL.createObjectURL(
          new Blob([response.request.response], {
            type: 'image/jpeg',
          })
        )
        dispatch(setRegulationPrediction(url));
        props.setShow(false);
        let selectedReportName = [];
        if (selectedReportNameL !== null) {
          selectedReportName = JSON.parse(selectedReportNameL);
        }
        selectedReportName.push(REPORT_NAME.REGULATION_PREDICTION);
        const jsonString = JSON.stringify(selectedReportName);
        localStorage.setItem('selectedReportName', jsonString);

      })
        .catch((error) => {
          console.log(error);
          alertMessage("Error while fetching data", "error");
        })
    }
  }

  const handleClose = () => props.setShow(false);

  //DataPrivacyPenaltyPredictionResponse.......
  useEffect(() => {
    if (dataPrivacyPenaltyPredictionResponse.isSuccess) {
      if (dataPrivacyPenaltyPredictionResponse.data !== null) {
        dispatch(setDataPrivacyPenaltyPrediction(dataPrivacyPenaltyPredictionResponse));
        // props.setDataPrivacyPenaltyPredDatasource(dataPrivacyPenaltyPredictionResponse.data.Countries);
        props.setShow(false);
        let selectedReportName = [];
        if (selectedReportNameL !== null) {
          selectedReportName = JSON.parse(selectedReportNameL);
        }
        selectedReportName.push(REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION);
        const jsonString = JSON.stringify(selectedReportName);
        localStorage.setItem('selectedReportName', jsonString);
      } else {
        const errorMsg = dataPrivacyPenaltyPredictionResponse.data.message;
        alertMessage(errorMsg, 'error');
        props.setShow(false);
      }
    } else if (dataPrivacyPenaltyPredictionResponse.isError) {
      alertMessage("error while fetching Data", "error");
    }
  }, [dataPrivacyPenaltyPredictionResponse.isSuccess, dataPrivacyPenaltyPredictionResponse.isError]);

  return (
    <div>
      {/* <!-- Modal --> */}
      <Modal
        show={props.show}
        onHide={handleClose}
        className=""
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header>
            <Modal.Title
              className="fw-bold text-primary"
              style={{ fontSize: "1rem" }}
            >
              {props.reportName}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            // validationSchema={formSchema}
            enableReinitialize={true}
            initialValues={props.reportNameCode === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION ? {
              annualUSD: "",
              numViolations: "",
            } : {
              totalPopulation: "",
              intialNoOfIncidents: "",
              transmissionRate: "",
              incidentRecoveryRate: "",
              numberOfDaysToSimulate: "",
              description: "",
            }}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange }) => (
              <Form>
                {props.reportNameCode === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION ? (
                  <div>
                    <p>
                      <div className="d-flex">
                        <div>Annual USD</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <div className="input-group mb-3">
                        <input
                          className="form-control form-control-sm "
                          type="text"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.annualUSD}
                          name="annualUSD"
                          required
                        />
                        <span className="input-group-text">M$</span>
                      </div>

                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Num Violations</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.numViolations}
                        name="numViolations"
                        required
                      />
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      <div className="d-flex">
                        <div>Total population:</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.totalPopulation}
                        name="totalPopulation"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Intial number of incidents (Intial number of incidents should be 1):</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.intialNoOfIncidents}
                        name="intialNoOfIncidents"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Transmission Rate (Average number of incidents caused by an incident per day):</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.transmissionRate}
                        name="transmissionRate"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Incident recovery rate (Incident recovery rate should be less than transmission rate):</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.incidentRecoveryRate}
                        name="incidentRecoveryRate"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Number of days to simulate:</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.numberOfDaysToSimulate}
                        name="numberOfDaysToSimulate"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Description:</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.description}
                        name="description"
                        required
                      />
                    </p>
                  </div>
                )}
                <div className="d-flex flex-row justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                  >
                    {/* {props.actionType === ACTION_TYPE.ADD_ACTION
                      ? "Save"
                      : "Update"} */}
                    Submit
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary btn-sm btn-block mt-3 mx-2 btn-width"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  {dataPrivacyPenaltyPredictionResponse.isLoading ||
                    regulationPredictionResponse.isLoading ?
                    (
                      <Loading />
                    ) : (
                      ""
                    )}
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default PredictionModelsData;
