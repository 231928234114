import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface TextMatcherState {
  data: [] | null | any;
  termComparisonAnalysis: [] | null | any;
  termComparisonAnalysisWithTermFile: [] | null | any;
  fileName: string | null;
}
const initialState: TextMatcherState = {
  data: null,
  termComparisonAnalysis: null,
  termComparisonAnalysisWithTermFile: null,
  fileName: null,
};
export const textMatcherSlice = createSlice({
  name: 'textMatcher',
  initialState,
  reducers: {
    setTermComparisonAnalysis: (state, action) => {
      state.termComparisonAnalysis = action.payload.data.data[0];
      // state.fileName = action.payload.data.data[0][0].fileName;
    },
    setTermComparisonAnalysisWithTermFile: (state, action) => {
      state.termComparisonAnalysisWithTermFile = action.payload.data.data[0];
      // state.fileName = action.payload.data.data[0][0].fileName;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setTermComparisonAnalysis, setTermComparisonAnalysisWithTermFile, reset } = textMatcherSlice.actions;
export const getTermComaprisonAnalysisDetails = (state: RootState) => state.textMatcher.termComparisonAnalysis;
export const getTermComaprisonAnalysisWithTermFileDetails = (state: RootState) => state.textMatcher.termComparisonAnalysisWithTermFile;
// export const getGdprFileName = (state: RootState) => state.gdpr.fileName;
export default textMatcherSlice.reducer;