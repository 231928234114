import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface AllValueChainCategoriesState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
}

const initialState: AllValueChainCategoriesState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
};

export const allValueChainCategoriesSlice = createSlice({
  name: 'allValueChainCategories',
  initialState,
  reducers: {
    setAllValueChainCategories: (state, action) => {
      state.data = action.payload.data.content;
      state.totalRecords = action.payload.data.totalElements;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.number;
    },
    setAllValueChainCategoriesBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAllValueChainCategories,setAllValueChainCategoriesBySearch,reset } = allValueChainCategoriesSlice.actions;
export const getAllValueChainCategoriesDetails = (state: RootState) => state.allValueChainCategories.data;
export const getAllValueChainCategoriesBySearchDetails= (state: RootState) => state.allValueChainCategories.data;
export const getValueChainCategoriesTotalRecords=(state:RootState)=>state.allValueChainCategories.totalRecords;
export default allValueChainCategoriesSlice.reducer;