import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { webUI, webUIBaseUrl } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const gdprService = createApi({
  reducerPath: "gdprService",
  baseQuery: fetchBaseQuery({ baseUrl: webUIBaseUrl }),
  tagTypes: ["Gdpr"],
  endpoints: (builder) => ({
    dataPrivacyComplianceTest: builder.mutation({
      query(body) {
        return {
          url: `${webUI.gdprController.dataPrivacyComplianceTest}`,
          method: 'POST',
          // headers: { 'Content-type': "application/json"},
          body: body.file,
        }
      }
    }),
    aiRegulationComplianceTest: builder.mutation({
      query(body) {
        return {
          url: `${webUI.gdprController.aiRegulationComplianceTest}`,
          method: 'POST',
          // headers: { 'Content-type': "application/json"},
          body: body.file,
        }
      }
    }),
  }),
});
export const {
 useDataPrivacyComplianceTestMutation,
 useAiRegulationComplianceTestMutation,
} = gdprService;
