import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface usersState {
  totalRecords: number | null;
  totalPage: number | null;
  currentPage: number | null;
  data: [] | null | any;
  findUserByIdData: null | null;
  updateUserData: null | null;
  deleteUserData: null | null;
  forgotPasswordData: null | null;
  resetPasswordData: null | null;
  uploadUserData: null | null;
  findUserNameData: null | null;
  uploadProfileImageData: null | null;
  downloadProfileImageData: any | null;
  uploadMerchantImageData: null | null;
  downloadMerchantImageData: any | null;
}

const initialState: usersState = {
  data: null,
  totalPage: null,
  currentPage: null,
  totalRecords: null,
  findUserByIdData: null,
  updateUserData: null,
  deleteUserData: null,
  forgotPasswordData: null,
  resetPasswordData: null,
  uploadUserData: null,
  findUserNameData: null,
  uploadProfileImageData: null,
  downloadProfileImageData: null,
  uploadMerchantImageData: null,
  downloadMerchantImageData: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    addNewUsers: (state: any, action) => {
      state.data = [action.payload.data, ...state.data];
    },
    reset: (state) => {
      state = initialState;
    },
    setFindUserByIdUser: (state, action) => {
      state.findUserByIdData = action.payload.data;
    },
    setUpdateUser: (state, action) => {
      state.updateUserData = action.payload.data;
    },
    setDeleteUser: (state, action) => {
      state.deleteUserData = action.payload.data;
    },
    setUsers: (state, action) => {
      state.data = action.payload.data;
    },
    setForgotPassword: (state, action) => {
      state.forgotPasswordData = action.payload.data;
    },
    setResetPassword: (state, action) => {
      state.resetPasswordData = action.payload.data;
    },
    setUplodUser: (state, action) => {
      state.uploadUserData = action.payload.data;
    },
    setFindUserName: (state, action) => {
      state.findUserNameData = action.payload.data;
    },
    setUploadProfileImage: (state, action) => {
      state.uploadProfileImageData = action.payload.data;
    },
    setDownloadProfileImage: (state, action) => {
      state.downloadProfileImageData = action.payload.data;
    },
    setUploadMerchantImage: (state, action) => {
      state.uploadMerchantImageData = action.payload.data;
    },
    setDownloadMerchantImage: (state, action) => {
      state.downloadMerchantImageData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUsers, addNewUsers, reset, setUpdateUser, setDeleteUser, setFindUserByIdUser, setForgotPassword, setResetPassword, setUplodUser, setFindUserName, setUploadProfileImage, setDownloadProfileImage, setUploadMerchantImage, setDownloadMerchantImage } =
  usersSlice.actions;
export const getFindUserByIdDetails = (state: RootState) =>
  state.users.findUserByIdData;
export const getUpdateUserDetails = (state: RootState) =>
  state.users.updateUserData;
export const getDeleteUserDetails = (state: RootState) =>
  state.users.deleteUserData;
export const getUserDetails = (state: RootState) => state.users.data;
export const getForgotPasswordDetails = (state: RootState) =>
  state.users.forgotPasswordData;
export const getResetPasswordDetails = (state: RootState) =>
  state.users.resetPasswordData;
export const getUploadUserDetails = (state: RootState) =>
  state.users.uploadUserData;
export const getFindUserNameDetails = (state: RootState) =>
  state.users.findUserNameData;
export const getUploadProfileImageDetails = (state: RootState) =>
  state.users.uploadProfileImageData;
export const getDownloadProfileImageDetails = (state: RootState) =>
  state.users.downloadProfileImageData;
export const getUploadMerchantImageDetails = (state: RootState) =>
  state.users.uploadMerchantImageData;
export const getDownloadMerchantIImageDetails = (state: RootState) =>
  state.users.downloadMerchantImageData;
export default usersSlice.reducer;
