import React from "react";
import { Tabs } from 'antd';
import "./settings.scss";
import DataGovernanceView from "./component/DataGovernanceView";
import ValueChainTracking from "./component/ValueChainTracking";
import PredictionModel from "./component/PredictionModel";
import DataSourceManagement from "./component/DataSourceManagement";
import DataTransformationView from "./component/DataTransformationView";
import SystemHealth from "./component/SystemHealth";
import DataPipeline from "./component/DataPipeline";
import DataQuality from "./component/DataQuality";

type Props = {};

const Settings = (props: Props) => {

  return (
    <div className="mt-4 overflow-y-auto">
      {/* Inlet */}
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="text-cm-active-primary1 fw-bolder text-primary-main">Settings</h4>
      </div>
      <div className="Navs w-400 ">
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Data Pipeline" key="1">
            <DataPipeline />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Data Quality" key="2">
            <DataQuality />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Data Source Management" key="3">
            <DataSourceManagement />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Data Transformation View" key="4">
            <DataTransformationView />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Prediction Model" key="5">
            <PredictionModel />
          </Tabs.TabPane>
          <Tabs.TabPane tab="System Health" key="6">
            <SystemHealth />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Data Governance View" key="7">
            <DataGovernanceView />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Value Chain Tracking" key="8">
            <ValueChainTracking />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Settings;
