import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface AllDataRecordsState {
  data: [] | null | any;
  content: [] | null | any;
  totalRecords: number | null;//total elecments
  totalPages: number | null;
  currentPage: number | null; //number
}
const initialState: AllDataRecordsState = {
  data: null,
  content: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
};
export const allDataRecordsSlice = createSlice({
  name: 'allDataRecords',
  initialState,
  reducers: {
    setAllDataRecords: (state, action) => {
      state.data = action.payload.data;
    },
    setAllDataRecordsWithPagination: (state, action) => {
      state.data = action.payload.data.content;
      state.totalRecords = action.payload.data.totalElements;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.number;
    },
    setAllDataRecordsBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllDataRecords, setAllDataRecordsWithPagination,setAllDataRecordsBySearch,reset } = allDataRecordsSlice.actions;
export const getAllDataRecordsDetails = (state: RootState) => state.allDataRecords.data;
export const getAllDataRecordsWithPaginationDetails = (state: RootState) => state.allDataRecords.data;
export const getAllDataRecordsDetailsBySearch = (state: RootState) => state.allDataRecords.data;
export const getAllDataRecordsTotalRecords = (state: RootState) => state.allDataRecords.totalRecords;
export default allDataRecordsSlice.reducer;