import { useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Field, Form, Formik } from "formik";
import { ACTION_TYPE, REPORT_NAME } from "../../constant/reportType";
import { useDispatch } from "react-redux";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { alertMessage } from "../../utils/AlertService";
import { useAppSelector } from "../../redux-toolkit/hooks";
import Loading from "../../component/Loading";
import { Checkbox, Input, Radio, RadioChangeEvent, Select, Space, Table } from "antd";
import { useGetAllParameterCategoryMutation, useGetAllParameterMutation } from "../../redux-toolkit/services/responsibilityService";
import { useFindAllSectionMasterMutation, useGetSectionMasterByIdMutation, useSaveSectionMasterMutation, useUpdateSectionMasterMutation } from "../../redux-toolkit/services/sectionMasterService";
import { getAllParameterCategoryDetails, getAllParameterDetails, setParameterCategoryData, setParameterData } from "../../redux-toolkit/slices/responsibilitySlice";
import { setCompany } from "../../redux-toolkit/slices/companySlice";
import { useGetAllCompaniesMutation } from "../../redux-toolkit/services/companyService";
import { useFindAllDocumentCategoryMutation, useGetDocumentCategoryByIdMutation, useSaveDocumentCategoryMutation, useUpdateDocumentCategoryMutation } from "../../redux-toolkit/services/documentCategoryService";
import { useFindAllDocumentMasterMutation, useGetDocumentMasterByIdMutation, useSaveDocumentMasterMutation, useUpdateDocumentMasterMutation } from "../../redux-toolkit/services/documentMasterService";
import { setAllDocumentCategory } from "../../redux-toolkit/slices/documentCategorySlice";
import { allSectionMasterDetails, setAllSectionMaster, setSectionMaster } from "../../redux-toolkit/slices/sectionMasterSlice";
import { alldocumentMasterDetails, setAllDocumentMaster, setDocumentMaster } from "../../redux-toolkit/slices/documentMasterSlice";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import JoditEditor from 'jodit-react';
import { useCreateDocumentMutation, useGetDocumentRecordByIdMutation, useUpdateDocumentRecordMutation } from "../../redux-toolkit/services/documentCreationService";
import { getDocumentRecordByIdData, setDocumentById, setPdfBlob, setPdfURL } from "../../redux-toolkit/slices/documentCreationSlice";
import { BsDashCircle } from "react-icons/bs";
import { Button } from "react-bootstrap";

const { Option, OptGroup } = Select;

type Props = {
  setShow: any;
  show: any;
  actionType: ACTION_TYPE;
  data?: any;
  setReportData?: any;
  getAllReportAsync: () => void;
  updateAllReportAsync: (values: any) => void;
  reportType?: any;
  reportName?: string;
  isCategory?: boolean;
  reportNameCode?: string;
  setCall?: any;
  call?: any;
  setFileName?: any;
  setFileFormat?: any;
};

const DocumentManagementData = (props: Props) => {
  const token: any = getLocalStorageData("localUser").token;
  const dispatch = useDispatch();
  const config = useMemo(() => ({
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: ''
  }), []);

  const editor = useRef(null);

  //state
  const [selectData, setSelectData]: any = useState();
  const [getByIdData, setGetByIdData]: any = useState([]);
  const [selectedSections, setSelectedSections]: any = useState([]);
  const [selectedParameterCategories, setSelectedParameterCategories]: any = useState([]);
  const [organizationData, setOrganizationData]: any = useState();
  const [file, setFile]: any = useState<File | null>(null);
  const [imageBase64, setImageBase64] = useState() as any;
  const [newImage, setNewImage] = useState(null) as any;
  const [filteredParameters, setFilteredParameters] = useState([]);
  const [parameters, setParameters]: any = useState([]);
  const [parameterCategories, setParameterCategories]: any = useState([]);
  const [dataSource, setDataSource]: any = useState([]);
  const [selectedDocumentMaster, setSelectedDocumentMaster] = useState('');
  const [fileName, setFileName] = useState('');
  const [waterMark, setWaterMark] = useState('');
  const [fileFormat, setFileFormat] = useState('');
  const [footerAlignment, setFooterAlignment] = useState('');
  const [pageAlignment, setPageAlignment] = useState('');
  const [logoAlignment, setLogoAlignment] = useState('');
  const [headerAlignment, setHeaderAlignment] = useState('');
  const [filteredSectionMaster, setFilteredSectionMaster] = useState([]);
  const [options, setOptions] = useState([]);
  const [paramCatParamIds, setParamCatParamIds]: any = useState();
  const [renderTypes, setRenderTypes] = useState<{ [key: string]: string }>({});
  const [gridFields, setGridFields] = useState<{ [key: string]: { key: string, value: string }[] }>({});

  const [editorsValues, setEditorsValues]: any = useState({});
  const [orderData, setOrderData]: any = useState({ sections: {}, categories: {} });
  const [usedOrderNumbers, setUsedOrderNumbers]: any = useState({ sections: new Set(), categories: new Set() });
  const [orderErrors, setOrderErrors] = useState({ sections: {}, categories: {} });

  //getData
  const [getAllParameterCateogry, parameterCategoryResponse]: any = useGetAllParameterCategoryMutation();
  const [getAllParameter, parameterResponse]: any = useGetAllParameterMutation();
  const [getAllDocumentCategories, documentCategoriesResponse] = useFindAllDocumentCategoryMutation();
  const [getAllSectionMaster, sectionMasterResponse] = useFindAllSectionMasterMutation();
  const [getAllDocumentMaster, documentMasterResponse] = useFindAllDocumentMasterMutation();

  //save
  const [saveSectionMaster, saveSectionMasterResponse] = useSaveSectionMasterMutation();
  const [saveDocumentCategory, saveDocumentCategoryResponse] = useSaveDocumentCategoryMutation();
  const [saveDocumentMaster, saveDocumentMasterResponse] = useSaveDocumentMasterMutation();

  //update
  const [updateSectionMaster, updateSectionMasterResponse] = useUpdateSectionMasterMutation();
  const [updateDocumentCategory, updateDocumentCategoryResponse] = useUpdateDocumentCategoryMutation();
  const [updateDocumentMaster, updateDocumentMasterResponse] = useUpdateDocumentMasterMutation();
  const [updateDocumentRecord, updateDocumentRecordResponse] = useUpdateDocumentRecordMutation();

  //getById mutation
  const [getSectionMasterById, getSectionMasterByIdResponse] = useGetSectionMasterByIdMutation();
  const [getAllCompanies, allCompaniesResponse] = useGetAllCompaniesMutation();
  const [getDocumentCategoryById, getDocumentCategoryByIdResponse] = useGetDocumentCategoryByIdMutation();
  const [getDocumentMasterById, getDocumentMasterByIdResponse] = useGetDocumentMasterByIdMutation();
  const [getDocumentRecordById, getDocumentRecordByIdResponse] = useGetDocumentRecordByIdMutation();

  const [generateDocument, generateDocumentResponse]: any = useCreateDocumentMutation();

  const allDocumentMaster = useAppSelector(alldocumentMasterDetails);
  const sectionMasterDetails: any = useAppSelector(allSectionMasterDetails);
  const getAllParameterData: any = useAppSelector(getAllParameterDetails);
  const getAllParameterCategoryData: any = useAppSelector(getAllParameterCategoryDetails);
  const documentRecord: any = useAppSelector(getDocumentRecordByIdData);

  // const [editorType, setEditorType] = useState(documentRecord ?
  //   (documentRecord.createDocumentReqDTO.descriptionType === "simple" ? 1 : documentRecord.createDocumentReqDTO.descriptionType === "rich" ? 2 : 1) : 1);

  const [editorType, setEditorType] = useState(1);

  console.log(editorType)
  useEffect(() => {
    if (getAllParameterData && getAllParameterCategoryData && props.reportNameCode === REPORT_NAME.SECTION_MASTER) {
      const transformedData = getAllParameterCategoryData.map((category: any, index: any) => {
        const options = getAllParameterData
          .filter((param: any) => param.categorycode === category.categorycode)
          .map((param: any) => ({
            label: param.parametercode,
            value: `${category.categoryid}-${param.parameterid}`,
          }));

        return {
          label: category.categoryname,
          value: category.categoryname,
          options: options,
          key: category.categoryid
        };
      });
      setOptions(transformedData)
    }
  }, [getAllParameterData, getAllParameterCategoryData])

  useEffect(() => {
    if (props.reportNameCode === REPORT_NAME.SECTION_MASTER) {
      getAllParameterCateogry({})
      getAllCompanies({});
      getAllParameter({});
    } else if (props.reportNameCode === REPORT_NAME.DOCUMENT_MASTER) {
      getAllSectionMaster({
        token: token
      })
      getAllCompanies({});
      getAllDocumentCategories({
        token: token,
      })
    } else if (props.reportNameCode === REPORT_NAME.DOCUMENT_CREATION) {
      getAllDocumentMaster({
        token: token,
      });
      getAllSectionMaster({
        token: token
      })
    }
  }, [props.reportNameCode]);

  useEffect(() => {
    if (allCompaniesResponse.isSuccess) {
      dispatch(setCompany(allCompaniesResponse));
      setOrganizationData(allCompaniesResponse.data)
    } else if (allCompaniesResponse.isError) {
      alertMessage("Error fetching companies", "error");
    }
  }, [allCompaniesResponse.isSuccess, allCompaniesResponse.isError]);

  useEffect(() => {
    if (documentCategoriesResponse.isSuccess) {
      dispatch(setAllDocumentCategory(documentCategoriesResponse.data));
      setSelectData(documentCategoriesResponse.data);
    } else if (documentCategoriesResponse.isError) {
      alertMessage("Error fetching doucment categories", "error");
    }
  }, [documentCategoriesResponse.isSuccess, documentCategoriesResponse.isError]);

  useEffect(() => {
    if (sectionMasterResponse.isSuccess) {
      if (props.reportNameCode === REPORT_NAME.DOCUMENT_CREATION) {
        dispatch(setAllSectionMaster(sectionMasterResponse));
      } else {
        dispatch(setAllSectionMaster(sectionMasterResponse));
      }
    } else if (sectionMasterResponse.isError) {
      alertMessage("Error fetching section master", "error");
    }
  }, [sectionMasterResponse.isSuccess, sectionMasterResponse.isError]);

  useEffect(() => {
    if (documentMasterResponse.isSuccess) {
      if (props.reportNameCode === REPORT_NAME.DOCUMENT_CREATION) {
        dispatch(setAllDocumentMaster(documentMasterResponse));
      } else {
        dispatch(setDocumentMaster(documentMasterResponse));
      }
    } else if (documentMasterResponse.isError) {
      alertMessage("Error fetching section master", "error");
    }
  }, [documentMasterResponse.isSuccess, documentMasterResponse.isError]);

  useEffect(() => {
    if (saveSectionMasterResponse.isSuccess) {
      alertMessage("Section master added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveSectionMasterResponse.isError) {
      let response: any = saveSectionMasterResponse?.error;
      alertMessage(response, "error");
    }
  }, [saveSectionMasterResponse.isSuccess, saveSectionMasterResponse.isError]);

  useEffect(() => {
    if (saveDocumentCategoryResponse.isSuccess) {
      alertMessage("Dcoument category added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveDocumentCategoryResponse.isError) {
      let response: any = saveDocumentCategoryResponse?.error;
      alertMessage(response, "error");
    }
  }, [saveDocumentCategoryResponse.isSuccess, saveDocumentCategoryResponse.isError]);

  useEffect(() => {
    if (saveDocumentMasterResponse.isSuccess) {
      alertMessage("Dcoument master added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveDocumentMasterResponse.isError) {
      let response: any = saveDocumentMasterResponse?.error;
      alertMessage(response, "error");
    }
  }, [saveDocumentMasterResponse.isSuccess, saveDocumentMasterResponse.isError]);

  useEffect(() => {
    if (updateSectionMasterResponse.isSuccess) {
      alertMessage("Section master updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateSectionMasterResponse.isError) {
      let response: any = updateSectionMasterResponse?.error;
      alertMessage(response.data.serviceErrors[0].msg, "error");
    }
  }, [updateSectionMasterResponse.isSuccess, updateSectionMasterResponse.isError]);

  useEffect(() => {
    if (updateDocumentCategoryResponse.isSuccess) {
      alertMessage("Document category updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateDocumentCategoryResponse.isError) {
      let response: any = updateDocumentCategoryResponse?.error;
      alertMessage(response, "error");
    }
  }, [updateDocumentCategoryResponse.isSuccess, updateDocumentCategoryResponse.isError]);

  useEffect(() => {
    if (updateDocumentMasterResponse.isSuccess) {
      alertMessage("Document master updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateDocumentMasterResponse.isError) {
      let response: any = updateDocumentMasterResponse?.error;
      alertMessage(response, "error");
    }
  }, [updateDocumentMasterResponse.isSuccess, updateDocumentMasterResponse.isError]);

  useEffect(() => {
    if (generateDocumentResponse.isSuccess) {
      const url = window.URL.createObjectURL(generateDocumentResponse.data);
      dispatch(setPdfURL(url));
      dispatch(setPdfBlob(generateDocumentResponse.data));
      props.setCall("Y");
      handleClose();
    } else if (generateDocumentResponse.isError) {
      let response: any = generateDocumentResponse?.error;
      alertMessage(response, "error");
    }
  }, [generateDocumentResponse.isSuccess, generateDocumentResponse.isError])

  useEffect(() => {
    if (updateDocumentRecordResponse.isSuccess) {
      const url = window.URL.createObjectURL(updateDocumentRecordResponse.data);
      dispatch(setPdfURL(url));
      dispatch(setPdfBlob(updateDocumentRecordResponse.data));
      alertMessage("Document record updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateDocumentRecordResponse.isError) {
      let response: any = updateDocumentRecordResponse?.error;
      alertMessage(response ? response : "Error while updating document record", "error");
    }
  }, [updateDocumentRecordResponse.isSuccess, updateDocumentRecordResponse.isError]);

  useEffect(() => {
    if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
      if (props.reportNameCode === REPORT_NAME.SECTION_MASTER) {
        getSectionMasterById({
          id: props?.data?.id,
          token: token
        });
      } else if (props.reportNameCode === REPORT_NAME.DOCUMENT_CATEGORY) {
        getDocumentCategoryById({
          id: props?.data?.id,
          token: token
        });
      } else if (props.reportNameCode === REPORT_NAME.DOCUMENT_MASTER) {
        getDocumentMasterById({
          id: props?.data?.id,
          token: token
        });
      } else if (props.reportNameCode === REPORT_NAME.DOCUMENT_CREATION) {
        getDocumentRecordById({
          id: props?.data?.id,
          token: token
        });
      }
    }
  }, [props.actionType]);

  useEffect(() => {
    if (getSectionMasterByIdResponse.isSuccess) {
      setGetByIdData(getSectionMasterByIdResponse.data);
      // setSelectedValue(getSectionMasterByIdResponse?.data?.industryId)
      // let tempId = [];
      selectedParameterCategories.push(parseInt(getSectionMasterByIdResponse?.data.parameterCategoryId))
      // setSelectedParameterCategories(tempId);
    } else if (getSectionMasterByIdResponse.isError) {
      let response: any = getSectionMasterByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [getSectionMasterByIdResponse.isSuccess, getSectionMasterByIdResponse.isError]);

  useEffect(() => {
    if (parameterCategoryResponse.isSuccess) {
      dispatch(setParameterCategoryData(parameterCategoryResponse.data));
      setSelectData(parameterCategoryResponse.data)
    } else if (parameterCategoryResponse.isError) {
      alertMessage("Error while fetching parameter category", "error");
    }
  }, [parameterCategoryResponse.isSuccess, parameterCategoryResponse.isError]);

  useEffect(() => {
    if (parameterResponse.isSuccess) {
      dispatch(setParameterData(parameterResponse.data));
      setFilteredParameters(parameterResponse.data);
    } else if (parameterResponse.isError) {
      alertMessage("Error while fetching parameter", "error");
    }
  }, [parameterResponse.isSuccess, parameterResponse.isError]);

  useEffect(() => {
    if (getDocumentCategoryByIdResponse.isSuccess) {
      setGetByIdData(getDocumentCategoryByIdResponse.data)
    } else if (getDocumentCategoryByIdResponse.isError) {
      let response: any = getDocumentCategoryByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [getDocumentCategoryByIdResponse.isSuccess, getDocumentCategoryByIdResponse.isError]);

  useEffect(() => {
    if (getDocumentMasterByIdResponse.isSuccess) {
      setGetByIdData(getDocumentMasterByIdResponse.data)
      setImageBase64(getDocumentMasterByIdResponse.data.headerLogo)
      let selectedSectionIds = getDocumentMasterByIdResponse?.data.sectionIds?.map((id: any) => id);
      setSelectedSections(selectedSectionIds);
    } else if (getDocumentMasterByIdResponse.isError) {
      let response: any = getDocumentMasterByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [getDocumentMasterByIdResponse.isSuccess, getDocumentMasterByIdResponse.isError]);

  useEffect(() => {
    // const initialize = async () => {
    if (getDocumentRecordByIdResponse.isSuccess) {
      setGetByIdData(getDocumentRecordByIdResponse.data)
      dispatch(setDocumentById(getDocumentRecordByIdResponse.data))
      const createDocumentReqDTO = getDocumentRecordByIdResponse.data?.createDocumentReqDTO;

      setFileName(createDocumentReqDTO.fileName);
      setFileFormat(createDocumentReqDTO.documentType);
      setLogoAlignment(createDocumentReqDTO.logoAlignment);
      setHeaderAlignment(createDocumentReqDTO.headerAlignment);
      setFooterAlignment(createDocumentReqDTO.footerAlignment);
      setPageAlignment(createDocumentReqDTO.pageAlignment);
      setWaterMark(createDocumentReqDTO.waterMark);
      setSelectedDocumentMaster(createDocumentReqDTO.documentMasterId);
      setEditorType(createDocumentReqDTO?.descriptionType === "simple" ? 1 : (createDocumentReqDTO.descriptionType === "rich" ? 2 : 1));

      const data = transformData(getDocumentRecordByIdResponse.data);
      setDataSource(data);

      // Setting initial render types
      const initialRenderTypes = createDocumentReqDTO.descriptionObjList.reduce((acc: any, item: any) => {
        acc[item.parameterCategoryName] = item.renderType || "normal";
        return acc;
      }, {});
      setRenderTypes(initialRenderTypes);

      // console.log(initialRenderTypes, 'initialRenderTypes')


      // Setting initial editors values
      const initialEditorsValues = createDocumentReqDTO.descriptionObjList.reduce((acc: any, item: any) => {
        acc[`description_${item.sectionId}_${item.categoryId}_${item.parameterId}`] = item.description;
        return acc;
      }, {});
      setEditorsValues(initialEditorsValues);

      // console.log(initialEditorsValues, 'initialEditorsValues')

      // Setting initial grid fields
      const initialGridFields = createDocumentReqDTO.descriptionObjList.reduce((acc: any, item: any) => {
        if (item.renderType === "grid") {
          const key = `${item.sectionId}_${item.categoryId}_${item.parameterId}`;
          acc[key] = item.description.map((desc: any, index: any) => ({ key: index, value: desc }));
        }
        return acc;
      }, {});
      setGridFields(initialGridFields);

      // console.log(initialGridFields, 'initialGridFields')

    } else if (getDocumentRecordByIdResponse.isError) {
      let response: any = getDocumentRecordByIdResponse?.error;
      alertMessage(response, "error");
    }
    // }

    // initialize();
  }, [getDocumentRecordByIdResponse.isSuccess, getDocumentRecordByIdResponse.isError]);

  useEffect(() => {
    if (documentRecord !== null) {
      const createDocumentReqDTO = documentRecord?.createDocumentReqDTO;

      setFileName(createDocumentReqDTO.fileName);
      setFileFormat(createDocumentReqDTO.documentType);
      setLogoAlignment(createDocumentReqDTO.logoAlignment);
      setHeaderAlignment(createDocumentReqDTO.headerAlignment);
      setFooterAlignment(createDocumentReqDTO.footerAlignment);
      setPageAlignment(createDocumentReqDTO.pageAlignment);
      setWaterMark(createDocumentReqDTO.waterMark);
      setSelectedDocumentMaster(createDocumentReqDTO.documentMasterId);
      setEditorType(createDocumentReqDTO?.descriptionType === "simple" ? 1 : (documentRecord.createDocumentReqDTO.descriptionType === "rich" ? 2 : 1));

      const data = transformData(documentRecord);
      setDataSource(data);

      console.log(data)

      // Setting initial render types
      const initialRenderTypes = createDocumentReqDTO.descriptionObjList.reduce((acc: any, item: any) => {
        acc[item.parameterCategoryName] = item.renderType || "normal";
        return acc;
      }, {});
      setRenderTypes(initialRenderTypes);

      // console.log(initialRenderTypes, 'initialRenderTypes')

      // Setting initial editors values
      const initialEditorsValues = createDocumentReqDTO.descriptionObjList.reduce((acc: any, item: any) => {
        acc[`description_${item.sectionId}_${item.categoryId}_${item.parameterId}`] = item.description;
        return acc;
      }, {});
      setEditorsValues(initialEditorsValues);

      // console.log(initialEditorsValues, 'initialEditorsValues')

      // Setting initial grid fields
      const initialGridFields = createDocumentReqDTO.descriptionObjList.reduce((acc: any, item: any) => {
        if (item.renderType === "grid") {
          const key = `${item.sectionId}_${item.categoryId}_${item.parameterId}`;
          acc[key] = item.description.map((desc: any, index: any) => ({ key: index, value: desc }));
        }
        return acc;
      }, {});
      setGridFields(initialGridFields);

      // console.log(initialGridFields, 'initialGridFields')

    }
  }, [documentRecord])

  useEffect(() => {
    if (sectionMasterDetails && props.reportNameCode === REPORT_NAME.DOCUMENT_MASTER) {
      setFilteredSectionMaster(sectionMasterDetails);
    }
  }, [sectionMasterDetails])

  useEffect(() => {
    if (props.reportNameCode === REPORT_NAME.DOCUMENT_CREATION) {
      const sections: any = [];
      const parameters: any = [];
      const parameterCategory: any = [];
      const selectedDocumentMaster1 = allDocumentMaster?.find((item: any) => parseInt(item.id) === parseInt(selectedDocumentMaster));
      selectedDocumentMaster1?.documentSectionMappings?.map((item: any) => {
        sections.push(item.sectionMaster.id)
        parameters.push({
          sectionId: item.sectionMaster.id,
          parameterId: item.sectionMaster.parameterId,
          parameterName: item.sectionMaster.parameterName
        });
        const existingParameterCategory = parameterCategory.find((pc: any) => pc.parameterCategoryId === item.sectionMaster.parameterCategoryId);
        if (!existingParameterCategory) {
          parameterCategory.push({
            parameterCategoryId: item.sectionMaster.parameterCategoryId,
            parameterCategoryName: item.sectionMaster.parameterCategoryName
          });
        }
      })

      setSelectedSections(sections);
      setParameters(parameters);
      setParameterCategories(parameterCategory);
      if (getByIdData.length <= 0) {
        setDataSource([]);
      }
    }

  }, [allDocumentMaster]) //selectedDocumentMaster

  useEffect(() => {
    if (props.reportNameCode === REPORT_NAME.DOCUMENT_CREATION) {

      if (sectionMasterDetails) {
        let section: any = [];
        sectionMasterDetails.filter((item: any) => selectedSections.includes(item.id)).forEach((item: any) => {
          section.push({ sectionMasterName: item.sectionMasterName, sectionMasterId: item.id });
        });

        const filteredDataSource = sectionMasterDetails
          .filter((section: any) => selectedSections.includes(section.id))
          .map((section: any) => ({
            sectionMasterName: section.sectionMasterName,
            parameterCategoryName: section.parameterCategoryName,
            parameterName: section.parameterName,
            descriptionKey: `description_${section.id}_${section.parameterCategoryId}_${section.parameterId}`,
            sectionId: section.id,
            categoryId: section.parameterCategoryId,
            parameterId: section.parameterId,
          }));

        setDataSource(filteredDataSource);

        const initialRenderTypes: { [key: string]: string } = {};
        filteredDataSource.forEach((item: any) => {
          initialRenderTypes[item.parameterCategoryName] = item.renderType || 'normal';
        });
        setRenderTypes(initialRenderTypes);
        if (getByIdData.length <= 0) {
          setGridFields({});
        }
      }
    }
  }, [sectionMasterDetails]) //selectedSections

  const handleClose = () => {
    if (props.reportNameCode === REPORT_NAME.DOCUMENT_CREATION) {
      setOrderData({ sections: {}, categories: {} });
      setUsedOrderNumbers({ sections: new Set(), categories: {} });
      setOrderErrors({ sections: {}, categories: {} });
    }
    props.setShow(false);
  };

  const handleChangeValue = (values: any) => {
    setSelectedSections(values);
  };

  const handleEditorChange = (key: any, value: any) => {
    setEditorsValues((prevValues: any) => ({ ...prevValues, [key]: value }));
  };

  // handleSubmit...........
  const handleSubmit = async (values: any) => {
    if (props.actionType === ACTION_TYPE.ADD_ACTION) {
      if (props.reportNameCode === REPORT_NAME.SECTION_MASTER) {

        const org = organizationData
          .find((item: any) => {
            return parseInt(item.companyid) === parseInt(values.organization);
          });

        const paramCatParamList = paramCatParamIds.map((ids: any) => {
          const [catId, paramId] = ids.split('-')
          const parameter = getAllParameterData.find((item: any) => parseInt(item.parameterid) === parseInt(paramId));
          return parameter ? { parameterId: paramId, parameterName: parameter.parametercode, parameterCategoryName: parameter.categorycode, parameterCategoryId: catId } : null;
        }).filter((item: any) => item !== null);

        const payload = {
          status: true,
          id: 0,
          sectionMasterName: values.sectionMasterName,
          sectionMasterCode: values.sectionMasterCode,
          organizationId: parseInt(values.organization),
          organizationName: org.organization,
          paramCatParamList: paramCatParamList
        }
        saveSectionMaster({
          token: token,
          payload: payload
        });

      } else if (props.reportNameCode === REPORT_NAME.DOCUMENT_CATEGORY) {

        const payload = {
          status: true,
          id: 0,
          name: values.documentCategoryName,
          code: values.documentCategoryCode,
        }
        saveDocumentCategory({
          token: token,
          payload: payload
        });
      } else if (props.reportNameCode === REPORT_NAME.DOCUMENT_MASTER) {

        const filteredRecord = selectData.find((record: any) => record.id === parseInt(values.documentCategoryId));
        const filteredOrgRecord = organizationData.find((record: any) => parseInt(record.companyid) === parseInt(values.organization));

        const payload: any = {
          status: true,
          id: 0,
          name: values.documentMasterName,
          code: values.documentMasterCode,
          documentDescription: values.documentDescription,
          sectionIds: selectedSections,
          documentCategoryName: filteredRecord ? filteredRecord.name : null,
          documentCategoryId: parseInt(values.documentCategoryId),
          header: values.header,
          footer: values.footer,
          // headerLogo: formData,
          organizationName: filteredOrgRecord ? filteredOrgRecord.organization : null,
          organizationId: values.organization,
          content: values.content
        }

        const formData = new FormData();

        formData.append('documentMasterReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));

        if (file) {
          formData.append('headerLogo', file);
        }

        saveDocumentMaster({
          token: token,
          payload: formData,
          // headerLogo: formData
        });
      } else if (props.reportNameCode === REPORT_NAME.DOCUMENT_CREATION) {

        props.setFileName(fileName);
        props.setFileFormat(fileFormat);

        const descriptions = dataSource.map((item: any) => {
          const key = `${item.sectionId}_${item.categoryId}_${item.parameterId}`;
          const currentRenderType = renderTypes[item.parameterCategoryName] || "normal";

          let description;
          if (editorType === 1) {
            if (currentRenderType === "normal") {
              description = values[item.descriptionKey];
            } else if (currentRenderType === "grid") {
              // Assign the array of values directly
              description = (gridFields[key] || []).map((field: any) => field.value);
            }
          } else {
            description = editorsValues[item.descriptionKey];
          }

          return {
            sectionMasterName: item.sectionMasterName,
            parameterCategoryName: item.parameterCategoryName,
            parameterName: item.parameterName,
            parameterId: item.parameterId,
            sectionId: item.sectionId,
            categoryId: item.categoryId,
            description: description,
            renderType: currentRenderType
          };
        });

        const payload = {
          fileName: fileName,
          documentType: "pdf", // fileFormat,
          documentMasterId: parseInt(selectedDocumentMaster),
          waterMark: waterMark,
          descriptionObjList: descriptions,
          footerAlignment: footerAlignment,
          pageAlignment: pageAlignment,
          logoAlignment: logoAlignment,
          headerAlignment: headerAlignment,
          descriptionType: editorType === 1 ? "simple" : "rich",
          sectionCategoryOrder: orderData,
        }

        generateDocument({
          token: token,
          payload: payload,
        });
      }
    } else if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
      if (props.reportNameCode === REPORT_NAME.SECTION_MASTER) {

        const org = organizationData
          .find((item: any) => {
            return parseInt(item.companyid) === parseInt(values.organization);
          })

        const parameterCat = selectData
          .find((item: any) => {
            return parseInt(item.categoryid) === parseInt(values.parameterCategory);
          })

        const parameter = getAllParameterData
          .find((item: any) => {
            return parseInt(item.parameterid) === parseInt(values.parameter);
          })

        updateSectionMaster({
          token: token,
          payload: {
            status: props.data.status,
            id: props.data.id,
            sectionMasterName: values.sectionMasterName,
            sectionMasterCode: values.sectionMasterCode,
            organizationId: parseInt(values.organization),
            organizationName: org.organization,
            // parameterCategories: filteredCategories,
            parameterCategoryName: parameterCat.categoryname,
            parameterCategoryId: values.parameterCategory,
            parameterName: parameter.name,
            parameterId: values.parameter,
          }
        })
      } else if (props.reportNameCode === REPORT_NAME.DOCUMENT_CATEGORY) {

        const payload = {
          status: props.data.status,
          id: props.data.id,
          name: values.documentCategoryName,
          code: values.documentCategoryCode,
        }
        updateDocumentCategory({
          token: token,
          payload: payload
        });
      } else if (props.reportNameCode === REPORT_NAME.DOCUMENT_MASTER) {
        const filteredRecord = selectData.find((record: any) => record.id === parseInt(values.documentCategoryId));
        const filteredOrgRecord = organizationData.find((record: any) => parseInt(record.companyid) === parseInt(values.organization));

        const payload = {
          status: props.data.status,
          id: props.data.id,
          name: values.documentMasterName,
          code: values.documentMasterCode,
          documentDescription: values.documentDescription,
          sectionIds: selectedSections,
          documentCategoryName: filteredRecord ? filteredRecord.name : null,
          documentCategoryId: parseInt(values.documentCategoryId),
          header: values.header,
          footer: values.footer,
          organizationName: filteredOrgRecord ? filteredOrgRecord.organization : null,
          organizationId: values.organization,
        }

        const formData = new FormData();

        formData.append('documentMasterReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));

        if (file) {
          formData.append('headerLogo', file);
        } else if (imageBase64) {
          try {
            const base64File = base64ToFile(imageBase64, 'headerLogo');
            formData.append('headerLogo', base64File);
          } catch (error) {
            console.error('Error converting base64 to file:', error);
          }
        }

        updateDocumentMaster({
          token: token,
          payload: formData,
          id: props.data.id
        });
      } else if (props.reportNameCode === REPORT_NAME.DOCUMENT_CREATION) {
        props.setFileName(fileName);
        props.setFileFormat(fileFormat);

        const descriptions = dataSource.map((item: any) => {
          const key = `${item.sectionId}_${item.categoryId}_${item.parameterId}`;
          const currentRenderType = renderTypes[item.parameterCategoryName] || "normal";

          let description;
          if (editorType === 1) {
            if (currentRenderType === "normal") {
              description = values[item.descriptionKey];
            } else if (currentRenderType === "grid") {
              // Assign the array of values directly
              description = (gridFields[key] || []).map((field: any) => field.value);
            }
          } else {
            description = editorsValues[item.descriptionKey];
          }

          return {
            sectionMasterName: item.sectionMasterName,
            parameterCategoryName: item.parameterCategoryName,
            parameterName: item.parameterName,
            parameterId: item.parameterId,
            sectionId: item.sectionId,
            categoryId: item.categoryId,
            description: description,
            renderType: currentRenderType
          };
        });

        const payload = {
          status: props.data.status,
          id: props.data.id,
          fileName: fileName,
          documentType: "pdf", // fileFormat,
          documentMasterId: parseInt(selectedDocumentMaster),
          waterMark: waterMark,
          descriptionObjList: descriptions,
          footerAlignment: footerAlignment,
          pageAlignment: pageAlignment,
          logoAlignment: logoAlignment,
          headerAlignment: headerAlignment,
          descriptionType: editorType === 1 ? "simple" : "rich",
          sectionCategoryOrder: orderData
        }

        updateDocumentRecord({
          token: token,
          payload: payload,
          id: props.data.id
        });
      }
    }
  };

  const onEditorChange = (e: RadioChangeEvent) => {
    setEditorType(e.target.value);
  };

  const handleOrderChange = (id: any, type: string, value: any, sectionName: any, categoryName: any) => {

    const numberValue: any = Number(value);
    let scopeKey: any = '';

    if (type === 'sections') {
      scopeKey = sectionName;
    } else {
      scopeKey = `${sectionName}_${categoryName}`;
    }

    setOrderErrors((prev: any) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [id]: false,
      },
    }));


    if (type === 'sections') {
      if (usedOrderNumbers.sections.has(numberValue)) {
        alertMessage(`Order number ${numberValue} is already used in sections. Please choose a different number.`, 'error');
        setOrderErrors((prev: any) => ({
          ...prev,
          [type]: {
            ...prev[type],
            [id]: true,
          },
        }));
        return;
      }
    } else {
      const categorySet = usedOrderNumbers.categories[sectionName] || new Set();
      if (categorySet.has(numberValue)) {
        alertMessage(`Order number ${numberValue} is already used in the parameter category for section ${sectionName}. Please choose a different number.`, 'error');
        setOrderErrors((prev: any) => ({
          ...prev,
          [type]: {
            ...prev[type],
            [id]: true,
          },
        }));
        return;
      }
    }

    setUsedOrderNumbers((prev: any) => {
      if (type === 'sections') {
        const updatedSections = new Set(prev.sections);
        updatedSections.add(numberValue);
        return { ...prev, sections: updatedSections };
      } else {
        const updatedCategories = { ...prev.categories };
        if (!updatedCategories[sectionName]) {
          updatedCategories[sectionName] = new Set();
        }
        updatedCategories[sectionName].add(numberValue);
        return { ...prev, categories: updatedCategories };
      }
    });

    setOrderData((prev: any) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [id]: numberValue
      }
    }));


  };

  const handleRenderTypeChange = (parameterCategoryName: string, value: string) => {
    setRenderTypes((prevRenderTypes) => ({
      ...prevRenderTypes,
      [parameterCategoryName]: value,
    }));
  };

  const handleGridFieldChange = (key: string, fieldKey: string, value: string) => {
    setGridFields((prevGridFields) => ({
      ...prevGridFields,
      [key]: prevGridFields[key].map((field) =>
        field.key === fieldKey ? { ...field, value } : field
      ),
    }));
  };

  const handleAddField = (key: string) => {
    setGridFields((prevGridFields) => ({
      ...prevGridFields,
      [key]: [...(prevGridFields[key] || []), { key: `new_${Date.now()}`, value: '' }],
    }));
  };

  const handleRemoveField = (key: string, fieldKey: string) => {
    setGridFields((prevGridFields) => ({
      ...prevGridFields,
      [key]: prevGridFields[key].filter((field) => field.key !== fieldKey),
    }));
  };

  const handleSearchSelect = (value: any) => {

    const filteredOptions = sectionMasterDetails.filter((section: any) =>
      section.sectionMasterName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSectionMaster(filteredOptions);
  };

  const handleChangeParameterCategory = (value: any) => {
    setParamCatParamIds(value)
  };

  const DOCUMENT_CREATION_COLUMN = [
    {
      title: 'Section Master Name',
      dataIndex: 'sectionMasterName',
      key: 'sectionMasterName',
    },
    {
      title: 'Section Order',
      key: 'sectionOrder',
      render: (_: any, record: any) => {
        const isFirstOccurrence = Object.keys(groupedData[record.sectionMasterName]).flatMap(key => groupedData[record.sectionMasterName][key]).indexOf(record) === 0;
        return isFirstOccurrence ? (
          <Input
            value={record.sectionOrder}
            onChange={(e: any) => handleOrderChange(record.sectionMasterName, 'sections', e.target.value, record.sectionMasterName, record.parameterCategoryName)}
          />
        ) : null;
      },
      width: 20,
    },
    {
      title: 'Parameter Category',
      dataIndex: 'parameterCategoryName',
      key: 'parameterCategoryName',
    },
    {
      title: 'Category Order',
      key: 'categoryOrder',
      render: (_: any, record: any) => {
        const isFirstOccurrence = groupedData[record.sectionMasterName][record.parameterCategoryName].indexOf(record) === 0;
        return isFirstOccurrence ? (
          <Input
            value={record.categoryOrder}
            onChange={(e) => handleOrderChange
              (`${record.sectionMasterName}_${record.parameterCategoryName}`,
                'categories', e.target.value, record.sectionMasterName, record.parameterCategoryName)}
          />
        ) : null;
      },
      width: 20,
    },
    {
      title: 'Render type',
      key: 'renderType',
      render: (_: any, record: any) => {
        const isFirstOccurrence = groupedData[record.sectionMasterName][record.parameterCategoryName].indexOf(record) === 0;
        return isFirstOccurrence ? (
          <select
            className="form-select form-select-sm w-100"
            aria-label=".form-select-sm example"
            onChange={(e) => handleRenderTypeChange(record.parameterCategoryName, e.target.value)}
            name="renderType"
            value={renderTypes[record.parameterCategoryName] || ''}
          >
            <option value="">Select type</option>
            <option value={"normal"}>Normal</option>
            <option value={"grid"}>Grid</option>
          </select>
        ) : null;
      },
      width: 20,
    },
    {
      title: 'Parameter',
      dataIndex: 'parameterName',
      key: 'parameterName',
    },
    {
      title: 'Description',
      key: 'description',
      render: (_: any, record: any) => {
        const currentRenderType = renderTypes[record.parameterCategoryName] || "normal";
        const key = `${record.sectionId}_${record.categoryId}_${record.parameterId}`;
        const descriptionValue = (editorsValues[`description_${record.sectionId}_${record.categoryId}_${record.parameterId}`]).toString() || (record.description).toString();

        return (
          <div>
            {editorType === 1 && currentRenderType === "normal" ? (
              <Field name={record.descriptionKey}>
                {/* {({ field }: any) => <Input.TextArea {...field} value={record.description} />} */}
                {({ field, form }: any) => <Input.TextArea {...field} value={record.description || form.values[record.descriptionKey]} />}
              </Field>
            ) : editorType === 1 && currentRenderType === "grid" ? (
              <div>
                {(gridFields[key] || []).map((field: any) => (
                  <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Input
                      value={field.value}
                      onChange={(e) => handleGridFieldChange(key, field.key, e.target.value)}
                    />
                    <BsDashCircle onClick={() => handleRemoveField(key, field.key)} />
                  </Space>
                ))}
                <div>
                  <Button type="button" onClick={() => handleAddField(key)}>
                    Add field
                  </Button>
                </div>
              </div>
            ) : (
              // editorType === 2 && (
              <JoditEditor
                ref={editor}
                value={descriptionValue}
                config={config}
                onBlur={(newContent) => handleEditorChange(
                  `description_${record.sectionId}_${record.categoryId}_${record.parameterId}`, newContent)}
                onChange={(newContent) => handleEditorChange(
                  `description_${record.sectionId}_${record.categoryId}_${record.parameterId}`, newContent)}
              />
              // )
            )}
          </div>
        )
      }
    },
  ]

  // Grouping data by section master name
  const groupedData = dataSource.reduce((acc: any, item: any) => {

    const sectionGroup = acc[item.sectionMasterName] || {};
    const categoryGroup = sectionGroup[item.parameterCategoryName] || [];

    categoryGroup.push(item);
    sectionGroup[item.parameterCategoryName] = categoryGroup;
    acc[item.sectionMasterName] = sectionGroup;

    return acc;
  }, {});

  const base64ToFile = (base64String: any, filename: any) => {
    // Check if the base64 string includes the prefix
    let base64Data = base64String;
    let mime = '';

    if (base64String.includes(',')) {
      const arr = base64String.split(',');
      mime = arr[0].match(/:(.*?);/)[1];
      base64Data = arr[1];
    } else {
      // Default to image/jpeg if the prefix is missing
      mime = 'image/jpeg';
    }

    const bstr = atob(base64Data);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const ext = mime.split('/')[1];
    return new File([u8arr], `${filename}.${ext}`, { type: mime });
  };

  const transformData = (data: any) => {

    const createDocumentReqDTO = data?.createDocumentReqDTO;

    const { sections: sections } = createDocumentReqDTO?.sectionCategoryOrder;
    const { categories: categories } = createDocumentReqDTO?.sectionCategoryOrder;

    return createDocumentReqDTO.descriptionObjList.map((item: any) => ({
      ...item,
      sectionMasterName: item.sectionMasterName,
      parameterCategoryName: item.parameterCategoryName,
      parameterName: item.parameterName,
      description: item.description,
      descriptionKey: `description_${item.sectionId}_${item.categoryId}_${item.parameterId}`,
      sectionOrder: sections ? sections[item.sectionMasterName] : '', // Default to 0 if not found
      categoryOrder: categories ? categories[`${item.sectionMasterName}_${item.parameterCategoryName}`] : '', // Default to 0 if not found
    }));

    // if (createDocumentReqDTO?.sectionCategoryOrder.sections !== undefined) {

    //   return createDocumentReqDTO.descriptionObjList.map((item: any) => ({
    //     ...item,
    //     sectionMasterName: item.sectionMasterName,
    //     parameterCategoryName: item.parameterCategoryName,
    //     parameterName: item.parameterName,
    //     description: item.description,
    //     descriptionKey: `description_${item.sectionId}_${item.categoryId}_${item.parameterId}`,
    //     sectionOrder: sections[item.sectionMasterName], // Default to 0 if not found
    //     categoryOrder: categories[`${item.sectionMasterName}_${item.parameterCategoryName}`], // Default to 0 if not found
    //   }));
    // } else {
    //   return createDocumentReqDTO.descriptionObjList.map((item: any) => ({
    //     ...item,
    //     sectionMasterName: item.sectionMasterName,
    //     parameterCategoryName: item.parameterCategoryName,
    //     parameterName: item.parameterName,
    //     description: item.description,
    //     descriptionKey: `description_${item.sectionId}_${item.categoryId}_${item.parameterId}`,
    //     sectionOrder: '',
    //     categoryOrder: '',
    //   }));
    // }
  };

  // upload image...............................
  const imageHandler = (e: any) => {

    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewImage(reader.result);
        setImageBase64(null); // Clear the initial image if a new one is uploaded
      };
      reader.readAsDataURL(file);
    }

    const fileName = e.target.files[0].name;
    // setFileName(fileName)
    const extension = fileName.lastIndexOf(".") + 1;
    const extFile = fileName.substr(extension, fileName.length).toLowerCase();

    if (extFile == "jpg" || extFile == "svg" || extFile == "png") {
      setFile(e.target.files[0]);
    } else {
      alertMessage("Only jpg, svg and png are allowed!", "error");
    }
  };

  return (
    <div>
      {/* <!-- Modal --> */}
      <Modal
        show={props.show}
        onHide={handleClose}
        className=""
        backdrop="static"
        keyboard={false}
        // dialogClassName={props.reportNameCode !== REPORT_NAME.DOCUMENT_CREATION ? "modal-90w" : undefined}
        size={props.reportNameCode === REPORT_NAME.DOCUMENT_CREATION ? "xl" : "lg"}
      >
        <Modal.Header>
          <Modal.Title
            className="fw-bold text-primary"
            style={{ fontSize: "1rem" }}
          >
            {props.actionType === ACTION_TYPE.ADD_ACTION ? "Add" : "Update"}{" "}
            {props.reportName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {parameterCategoryResponse.isLoading ||
            saveSectionMasterResponse.isLoading ||
            getSectionMasterByIdResponse.isLoading ||
            updateSectionMasterResponse.isLoading ||
            saveDocumentCategoryResponse.isLoading ||
            updateDocumentCategoryResponse.isLoading ||
            getDocumentCategoryByIdResponse.isLoading ||
            documentCategoriesResponse.isLoading ||
            saveDocumentMasterResponse.isLoading ||
            updateDocumentMasterResponse.isLoading ||
            getDocumentMasterByIdResponse.isLoading ||
            generateDocumentResponse.isLoading ||
            documentMasterResponse.isLoading ||
            parameterResponse.isLoading ||
            getDocumentRecordByIdResponse.isLoading ||
            sectionMasterResponse.isLoading ||
            updateDocumentRecordResponse.isLoading ||
            allCompaniesResponse.isLoading ? (
            <Loading />
          ) : (
            ""
          )}
          <Formik
            // validationSchema={formSchema}
            enableReinitialize={true}
            initialValues={dataSource.reduce((acc: any, cur: any) => {
              acc[cur.descriptionKey] = '';
              return acc;
            }, (props.reportNameCode === REPORT_NAME.SECTION_MASTER) ? {
              sectionMasterName:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.sectionMasterName
                  : "",
              sectionMasterCode:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.sectionMasterCode
                  : "",
              organization:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.organizationId
                  : "",
              parameterCategory:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.parameterCategoryId
                  : "",
              parameter:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.parameterId
                  : "",
            } : (props.reportNameCode === REPORT_NAME.DOCUMENT_CATEGORY) ? {
              documentCategoryName:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.name
                  : "",
              documentCategoryCode:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.code
                  : "",
            } : (props.reportNameCode === REPORT_NAME.DOCUMENT_MASTER) ? {
              documentMasterName:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.name
                  : "",
              documentMasterCode:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.code
                  : "",
              documentDescription:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.documentDescription
                  : "",
              documentCategoryId:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.documentCategoryId
                  : "",
              header:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.header
                  : "",
              footer:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.footer
                  : "",
              organization:
                props.actionType === ACTION_TYPE.UPDATE_ACTION
                  ? getByIdData && getByIdData.organizationId
                  : "",
              headerLogo: null,
              content: props.actionType === ACTION_TYPE.UPDATE_ACTION
                ? getByIdData && getByIdData.content
                : "",
            } : {
              fileFormat: "",
              fileName: "",
              documentMaster: "",
              waterMark: "",
            })
            }
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, setFieldValue, setValues }) => (
              <Form>
                {(props.reportNameCode === REPORT_NAME.SECTION_MASTER ? (
                  <div>
                    <p>
                      <div className="d-flex">
                        <div> {props.reportName} Name</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.sectionMasterName}
                        name="sectionMasterName"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div> {props.reportName} Code</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.sectionMasterCode}
                        name="sectionMasterCode"
                      // required
                      />
                    </p>
                    <p>
                      <div className="w-100">
                        <div className="d-flex">
                          <div>
                            {" "}
                            Organization
                          </div>
                          <div style={{ color: "red" }}>*</div>
                        </div>
                        <div className="pe-2">
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label=".form-select-sm example"
                            onChange={handleChange}
                            name="organization"
                            value={values.organization}
                          // required
                          >
                            <option value="">
                              Select Organization
                            </option>
                            {organizationData &&
                              organizationData.map((itemType: any, index: any) => {
                                return (
                                  <option
                                    key={index.toString()}
                                    value={itemType.companyid}
                                  >
                                    {itemType.companyid} - {itemType.organization}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </p>
                    {props.actionType === ACTION_TYPE.ADD_ACTION ? (
                      <>
                        <div>
                          <div className="d-flex">
                            <div>Category</div>
                            <div style={{ color: "red" }}>*</div>
                          </div>
                          <Select
                            mode="multiple"
                            placeholder="Select"
                            style={{ width: '100%' }}
                            onChange={handleChangeParameterCategory}
                            virtual={false}
                          >
                            {options.map((group: any) => (
                              <OptGroup key={group.key} label={group.label}>
                                {group.options.map((option: any) => (
                                  <Option key={option.value} value={option.value}>
                                    {option.label}
                                  </Option>
                                ))}
                              </OptGroup>
                            ))}
                          </Select>
                        </div>
                      </>
                    ) : (
                      <>
                        <p>
                          <div className="w-100">
                            <div className="d-flex">
                              <div>
                                {" "}
                                Parameter Category
                              </div>
                              <div style={{ color: "red" }}>*</div>
                            </div>
                            <div className="pe-2">
                              <select
                                className="form-select form-select-sm w-100"
                                aria-label=".form-select-sm example"
                                // onChange={handleChange}
                                onChange={(e) => {
                                  setFieldValue('parameterCategory', e.target.value);

                                  const selectedCategory = selectData.find((item: any) => parseInt(item.categoryid) === parseInt(e.target.value));
                                  const filteredParams = getAllParameterData.filter(
                                    (param: any) => param.categorycode === selectedCategory.categorycode
                                  );
                                  setFilteredParameters(filteredParams);
                                  setFieldValue('parameter', ''); // Reset parameter when category changes
                                }}
                                name="parameterCategory"
                                value={values.parameterCategory}
                              >
                                <option value="">
                                  Select Parameter Category
                                </option>
                                {selectData &&
                                  selectData.map((itemType: any, index: any) => {
                                    return (
                                      <option
                                        key={index.toString()}
                                        value={itemType.categoryid}
                                      >
                                        {itemType.categoryid} - {itemType.categoryname}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        </p>
                        <div className="pe-2">
                          <div className="d-flex">
                            <div>
                              {" "}
                              Parameter
                            </div>
                            <div style={{ color: "red" }}>*</div>
                          </div>
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label=".form-select-sm example"
                            onChange={handleChange}
                            name="parameter"
                            value={values.parameter}
                          >
                            <option value="">
                              Select Parameter
                            </option>
                            {filteredParameters &&
                              filteredParameters.map((itemType: any, index: any) => {
                                return (
                                  <option
                                    key={index.toString()}
                                    value={itemType.parameterid}
                                  >
                                    {itemType.parameterid} - {itemType.parametercode}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </>
                    )}
                  </div>
                ) : props.reportNameCode === REPORT_NAME.DOCUMENT_CATEGORY ? (
                  <div>
                    <p>
                      <div className="d-flex">
                        <div> {props.reportName} Name</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.documentCategoryName}
                        name="documentCategoryName"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div> {props.reportName} Code</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.documentCategoryCode}
                        name="documentCategoryCode"
                      // required
                      />
                    </p>
                  </div>
                ) : props.reportNameCode === REPORT_NAME.DOCUMENT_MASTER ? (
                  <div>
                    <p>
                      <div className="d-flex">
                        <div> {props.reportName} Name</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.documentMasterName}
                        name="documentMasterName"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div> {props.reportName} Code</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.documentMasterCode}
                        name="documentMasterCode"
                      // required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div> {props.reportName} Description</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <ReactQuill
                        value={values.documentDescription}
                        onChange={(content, delta, source, editor) => {
                          setFieldValue('documentDescription', content);
                        }}
                      />
                    </p>
                    <p>
                      <div className="w-100">
                        <div className="d-flex">
                          <div>
                            {" "}
                            Select Document Category
                          </div>
                          <div style={{ color: "red" }}>*</div>
                        </div>
                        <div className="pe-2">
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label=".form-select-sm example"
                            onChange={handleChange}
                            name="documentCategoryId"
                            value={values.documentCategoryId}
                            required
                          >
                            <option value="">Select Document Category</option>
                            {selectData &&
                              selectData.map((itemType: any, index: any) => {
                                return (
                                  <option
                                    key={index.toString()}
                                    value={itemType.id}
                                  >
                                    {itemType.id} - {itemType.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </p>
                    <p>
                      <div className="d-flex">
                        <div> {props.reportName} Header</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <ReactQuill
                        value={values.header}
                        onChange={(content, delta, source, editor) => {
                          setFieldValue('header', content);
                        }}
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div> {props.reportName} Footer</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <ReactQuill
                        value={values.footer}
                        onChange={(content, delta, source, editor) => {
                          setFieldValue('footer', content);
                        }}
                      />
                    </p>
                    <p>
                      <div className="w-100">
                        <div className="d-flex">
                          <div>
                            {" "}
                            Organization
                          </div>
                          <div style={{ color: "red" }}>*</div>
                        </div>
                        <div className="pe-2">
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label=".form-select-sm example"
                            onChange={handleChange}
                            name="organization"
                            value={values.organization}
                          // required
                          >
                            <option value="">
                              Select Organization
                            </option>
                            {organizationData &&
                              organizationData.map((itemType: any, index: any) => {
                                return (
                                  <option
                                    key={index.toString()}
                                    value={itemType.companyid}
                                  >
                                    {itemType.companyid} - {itemType.organization}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Sections</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <Select
                        mode="multiple"
                        placeholder="Select section"
                        value={selectedSections}
                        onChange={handleChangeValue}
                        style={{ width: '100%' }}
                        showSearch
                        filterOption={false}
                        onSearch={handleSearchSelect}
                      >
                        {/* Render options with checkboxes */}
                        {filteredSectionMaster && filteredSectionMaster.map((section: any) => {
                          return (
                            <Option key={section.id} value={section.id}>
                              <Checkbox checked={selectedSections.includes(section.id)} className="me-2" />
                              {" "}{section.sectionMasterName}
                              <div style={{ fontSize: 'small', color: 'gray', marginLeft: "30px" }}>
                                {section.parameterCategoryName}
                              </div>
                              <div style={{ fontSize: 'small', color: 'gray', marginLeft: "40px" }}>
                                {section.parameterName}
                              </div>
                            </Option>
                          )
                        })}
                      </Select>
                    </p>
                    <div className="">
                      <p>Upload Logo</p>
                      {imageBase64 && !newImage && (
                        <img src={`data:image/png;base64,${imageBase64}`} alt="Current Logo" style={{ width: '50px', height: '50px' }} />
                      )}
                      {newImage && (
                        <img src={newImage} alt="New Logo" style={{ width: '50px', height: '50px' }} />
                      )}
                      <input
                        type="file"
                        id="fileUpload"
                        className="ms-2"
                        onChange={(e: any) => {
                          imageHandler(e);
                          setFieldValue("headerLogo", e.target.files[0]);
                        }}
                      />
                      <hr />
                    </div>
                  </div>
                ) : (
                  <div>
                    <p>
                      <div className="d-flex">
                        <div>File name</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={(e) => setFileName(e.target.value)}
                        value={fileName}
                        name="fileName"
                      />
                    </p>
                    {/* <div className="pe-2">
                      <div className="d-flex">
                        <div>File Format</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <select
                        className="form-select form-select-sm w-100"
                        aria-label=".form-select-sm example"
                        onChange={(e) => setFileFormat(e.target.value)}
                        name="fileFormat"
                        value={fileFormat}
                      >
                        <option value="">Select File Format</option>
                        <option value={"pdf"}>PDF</option>
                        <option value={"docx"}>DOCX</option>
                      </select>
                    </div> */}
                    <div className="pe-2 mt-3">
                      <div className="d-flex">
                        <div>Logo Alignment</div>
                        {/* <div style={{ color: "red" }}>*</div> */}
                      </div>
                      <select
                        className="form-select form-select-sm w-100"
                        aria-label=".form-select-sm example"
                        onChange={(e) => setLogoAlignment(e.target.value)}
                        name="logoAlignment"
                        value={logoAlignment}
                      >
                        <option value="">Select alignment</option>
                        <option value={"right"}>Right</option>
                        {/* <option value={"center"}>Center</option> */}
                        <option value={"left"}>Left</option>
                      </select>
                    </div>
                    <div className="pe-2 mt-3">
                      <div className="d-flex">
                        <div>Header Alignment</div>
                        {/* <div style={{ color: "red" }}>*</div> */}
                      </div>
                      <select
                        className="form-select form-select-sm w-100"
                        aria-label=".form-select-sm example"
                        onChange={(e) => setHeaderAlignment(e.target.value)}
                        name="headerAlignment"
                        value={headerAlignment}
                      >
                        <option value="">Select alignment</option>
                        <option value={"right"}>Right</option>
                        <option value={"center"}>Center</option>
                        <option value={"left"}>Left</option>
                      </select>
                    </div>
                    <div className="pe-2 mt-3">
                      <div className="d-flex">
                        <div>Footer Alignment</div>
                        {/* <div style={{ color: "red" }}>*</div> */}
                      </div>
                      <select
                        className="form-select form-select-sm w-100"
                        aria-label=".form-select-sm example"
                        onChange={(e) => setFooterAlignment(e.target.value)}
                        name="footerAlignment"
                        value={footerAlignment}
                      >
                        <option value="">Select alignment</option>
                        <option value={"right"}>Right</option>
                        <option value={"center"}>Center</option>
                        <option value={"left"}>Left</option>
                      </select>
                    </div>
                    <div className="pe-2 mt-3">
                      <div className="d-flex">
                        <div>Page No. Alignment</div>
                        {/* <div style={{ color: "red" }}>*</div> */}
                      </div>
                      <select
                        className="form-select form-select-sm w-100"
                        aria-label=".form-select-sm example"
                        onChange={(e) => setPageAlignment(e.target.value)}
                        name="pageAlignment"
                        value={pageAlignment}
                      >
                        <option value="">Select alignment</option>
                        <option value={"right"}>Right</option>
                        <option value={"center"}>Center</option>
                        <option value={"left"}>Left</option>
                      </select>
                    </div>
                    <p>
                      <div className="d-flex mt-3">
                        <div>Watermark</div>
                        {/* <div style={{ color: "red" }}>*</div> */}
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={(e) => setWaterMark(e.target.value)}
                        value={waterMark}
                        name="waterMark"
                      />
                    </p>
                    <div className="w-100 mt-3">
                      <div className="d-flex">
                        <div>
                          {" "}
                          Document Master Template
                        </div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <div className="pe-2">
                        <select
                          className="form-select form-select-sm w-100"
                          aria-label=".form-select-sm example"
                          onChange={(e) => {
                            const selected = e.target.value
                            setSelectedDocumentMaster(selected);
                            setOrderData({ sections: {}, categories: {} });
                            setUsedOrderNumbers({ sections: new Set(), categories: {} });
                            setOrderErrors({ sections: {}, categories: {} });
                            setGetByIdData([]);
                            setGridFields({});
                          }}
                          name="documentMaster"
                          value={selectedDocumentMaster}
                          required
                        >
                          <option value="">
                            Select Document Master
                          </option>
                          {allDocumentMaster &&
                            allDocumentMaster.map((itemType: any, index: any) => {
                              return (
                                <option
                                  key={index.toString()}
                                  value={itemType.id}
                                >
                                  {itemType.id} - {itemType.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    {/* <div className="w-100 mt-3">
                      <div className="d-flex align-items-center">
                        <p className="m-0">Choose Type for parameter : </p>
                        <Radio.Group onChange={(e) => setParameterType(e.target.value)} value={parameterType} className="px-2">
                          <Radio value={1}>Normal render</Radio>
                          <Radio value={2}>Grid</Radio>
                        </Radio.Group>
                      </div>
                    </div> */}
                    <div className="w-100 mt-3">

                      <div className="d-flex align-items-center">
                        <p className="m-0">Choose Type for description : </p>
                        <Radio.Group onChange={onEditorChange} value={editorType} className="px-2">
                          <Radio value={1}>Simple Text</Radio>
                          <Radio value={2}>Rich Text Editor</Radio>
                        </Radio.Group>
                      </div>
                      <div className="mt-3">
                        {dataSource.length > 0 && (
                          <Table
                            scroll={{ x: "max-content" }}
                            dataSource={dataSource}
                            columns={DOCUMENT_CREATION_COLUMN}
                            pagination={false}
                            rowKey={(record) => `${record.sectionId}_${record.categoryId}_${record.parameterId}`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )
                )}
                <div className="d-flex flex-row justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                  >
                    {props.actionType === ACTION_TYPE.ADD_ACTION
                      ? props.reportNameCode === REPORT_NAME.DOCUMENT_CREATION ? "Generate" : "Save"
                      : "Update"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm btn-block mt-3 mx-2 btn-width"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default DocumentManagementData;
