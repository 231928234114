import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface AllValueChainParagraphExtractState {
  data: [] | null | any;
  content: [] | null | any;
  totalRecords: number | null;//total elecments
  totalPages: number | null;
  currentPage: number | null; //number
}
const initialState: AllValueChainParagraphExtractState = {
  data: null,
  content: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
};
export const   allValueChainParagraphExtractSlice = createSlice({
  name: 'allValueChainParagraphExtract',
  initialState,
  reducers: {
    setAllValueChainParagraphExtract: (state, action) => {
      state.data = action.payload.data;
    },
    setAllValueChainParagraphExtractWithPagination: (state, action) => {
      state.data = action.payload.data.content;
      state.totalRecords = action.payload.data.totalElements;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.number;
    },
    setAllValueChainParagraphExtractBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllValueChainParagraphExtract, setAllValueChainParagraphExtractWithPagination, setAllValueChainParagraphExtractBySearch,reset } = allValueChainParagraphExtractSlice.actions;
export const getValueChainParagraphExtractDetails = (state: RootState) => state.allValueChainParagraphExtract.data;
export const getValueChainParagraphExtractWithPaginationDetails = (state: RootState) => state.allValueChainParagraphExtract.data;
export const getValueChainParagraphExtractDetailsBySearch = (state: RootState) => state.allValueChainParagraphExtract.data;
export const getValueChainParagraphExtractTotalRecords = (state: RootState) => state.allValueChainParagraphExtract.totalRecords;
export default allValueChainParagraphExtractSlice.reducer;