import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { useDeleteUserByIdMutation } from "../../redux-toolkit/services/allUserService";
import { alertMessage } from "../../utils/AlertService";
import { Spin } from "antd";

type Props = {};

const Verify = () => {
    const navigate = useNavigate();
    const currentUrl = window.location.href
    const id = currentUrl.slice(currentUrl.indexOf('=') + 1);

    const [deleteUser, deleteUserResponse] = useDeleteUserByIdMutation();


    useEffect(() => {
        deleteUser({
            // token: token,
            id: parseInt(id),
        });
    }, []);

    useEffect(() => {
        if (deleteUserResponse.isSuccess) {
            alertMessage("Verification is successfull", "success");
            navigate("/home");
        } else if (deleteUserResponse.isError) {
            let response: any = deleteUserResponse.error;
            alertMessage("Error while verification", "error");
        }
    }, [deleteUserResponse.isSuccess, deleteUserResponse.isError]);

    return (
        <div className="cm-sidebar-layout d-flex vh-100">
            <div
                className="flex-fill  bg-cm-light-primary  vh-100 d-flex flex-column"
                style={{ width: "80%" }}
            >
                <div className="cm-header sticky-top bg-cm-light-primary1 d-flex align-items-center justify-content-between p-3">
                    <img
                        src={require("../../assets/logo-blue.png")}
                        height={40}
                        width={100}
                        alt=""
                    />
                </div>
                <div className="cm-content-wrapper p-3 px-md-4 px-3 h-100 flex-fill">
                    <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                        <h3 className="text-cm-primary1 fw-bold">Verification in process please wait</h3><Spin />
                    </div>
                </div>
                <footer className="text-align-center footer">
                    <p className="text-cm-dark fs-6">
                        Capitol Tunnels.ai &#169; 2023
                    </p>
                </footer>

            </div>
        </div>
    );
};
export default Verify;
