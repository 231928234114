import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { dataServiceBaseUrl, dataService } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const allValueChainParagraphExtractService = createApi({
    reducerPath: 'allValueChainParagraphExtractService',
    baseQuery: fetchBaseQuery({ baseUrl: dataServiceBaseUrl }),
    tagTypes: ['ValueChainParagraphExtract'],
    endpoints: (builder) => ({
        getAllValueChainParagraphExtract: builder.mutation({
            query() {
                return {
                    url: `${dataService.valueChainParagraphExtractController.getAllValueChainParagraphExtract}`,
                    method: 'GET',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                }
            }
        }),
        getAllValueChainParagraphExtractWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.valueChainParagraphExtractController.getAllValueChainParagraphExtractWithSearch}`,
                    method: 'POST',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        getValueChainParagraphExtractById: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.valueChainParagraphExtractController.getValueChainParagraphExtractById}/${body.id}`,
                    method: 'GET',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                }
            }
        }),
        saveValueChainParagraphExtract: builder.mutation({
            query(body){
                return {
                    url: `${dataService.valueChainParagraphExtractController.saveValueChainParagraphExtract}`,
                    method: 'POST',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateValueChainParagraphExtract: builder.mutation({
            query(body){
                return {
                    url: `${dataService.valueChainParagraphExtractController.updateValueChainParagraphExtract}/${body.payload.id}`,
                    method: 'PUT',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteValueChainParagraphExtractById: builder.mutation({
            query(body){
                return {
                    url: `${dataService.valueChainParagraphExtractController.deleteValueChainParagraphExtractById}/${body.id}`,
                    method: 'DELETE',
                    // headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAllValueChainParagraphExtractWithPagination: builder.mutation({
            query(body){
                return {
                    url: `${dataService.valueChainParagraphExtractController.getAllValueChainParagraphExtractWithPagination}?page=${body.page}&size=${body.size}&sort=${body.sort}`,
                    method: 'GET',
                }
            }
        })
    })
  });
  export const {
   useGetAllValueChainParagraphExtractMutation,
   useSaveValueChainParagraphExtractMutation,
   useUpdateValueChainParagraphExtractMutation,
   useDeleteValueChainParagraphExtractByIdMutation,
   useGetValueChainParagraphExtractByIdMutation,
   useGetAllValueChainParagraphExtractWithPaginationMutation,
   useGetAllValueChainParagraphExtractWithSearchMutation
  } = allValueChainParagraphExtractService;