
type Props = {};

const DataQuality = (props: Props) => {
  return (
    <div className="mt-4 overflow-y-auto">
      {/* Inlet */}
     
    </div>
  );
};

export default DataQuality;
