import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userManagement, userManagementBaseUrl } from "../endpoints";

// Define a service using a base URL and expected endpoints

export const usersService = createApi({
  reducerPath: "usersService",
  baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
  tagTypes: ["users"],
  endpoints: (builder) => ({
    addUser: builder.mutation({
      query(body) {
        return {
          url: `${userManagement.userController.addUser}`,
          method: "POST",
          // headers: { Authorization: `Bearer ${body.token}` },
          body: body.payload
        };
      }
    }),
    findUserById: builder.mutation({
      query(body) {
        return {
          url: `${userManagement.userController.findUserById}/${body.id}`,
          method: "GET",
          headers: { Authorization: `Bearer ${body.token}` }
        };
      }
    }),
    updateUser: builder.mutation({
      query(body) {
        return {
          url: `${userManagement.userController.updateUser}/${body.id}`,
          method: "PUT",
          headers: { Authorization: `Bearer ${body.token}` },
          body: body.payload
        };
      }
    }),
    deleteUser: builder.mutation({
      query(body) {
        return {
          url: `${userManagement.userController.deleteUser}/${body.payload.id}`,
          method: "DELETE",
          headers: { Authorization: `Bearer ${body.token}` }
        };
      }
    }),
    getUsers: builder.mutation({
      query(body) {
        return {
          url: `${userManagement.userController.getUsers}/${body.payload.pageNumber}/${body.payload.offset}`,
          method: "GET",
          headers: { Authorization: `Bearer ${body.token}` }
        };
      }
    }),
    forgotPassword: builder.mutation({
      query(body) {
        return {
          url: `${userManagement.userController.forgotPassword}/${body.payload.email}`,
          method: "PUT",
          headers: { Authorization: `Bearer ${body.token}` },
          body: body.payload
        };
      }
    }),
    resetPassword: builder.mutation({
      query(body) {
        return {
          url: `${userManagement.userController.resetPassword}`,
          method: "POST",
          headers: { Authorization: `Bearer ${body.token}` },
          body: body.payload
        };
      }
    }),
    uploadUsers: builder.mutation({
      query(body) {
        return {
          url: `${userManagement.userController.uploadUsers}`,
          method: "POST",
          headers: { Authorization: `Bearer ${body.token}` },
          body: body.payload
        };
      }
    }),
    findUsername: builder.mutation({
      query(body) {
        return {
          url: `${userManagement.userController.findUsername}/${body.payload.username}`,
          method: "GET",
          headers: { Authorization: `Bearer ${body.token}` }
        };
      }
    }),
    uploadProfileImage: builder.mutation({
      query(body) {
        return {
          url: `${userManagement.userController.uploadProfileImage}/${body.username}`,
          method: "POST",
          headers: { Authorization: `Bearer ${body.token}` },
          body: body.file
        };
      }
    }),
    downloadProfileImage: builder.mutation({
      query(body) {
        return {
          url: `${userManagement.userController.downloadProfileImage}/${body.username}`,
          method: "GET",
          headers: { Authorization: `Bearer ${body.token}` }
        };
      }
    }),
    uploadMerchantImage: builder.mutation({
      query(body) {
        return {
          url: `${userManagement.userController.saveMerchant}/${body.merchantId}/${body.companyName}`,
          method: "POST",
          headers: { Authorization: `Bearer ${body.token}` },
          body: body.file
        };
      }
    }),
    downloadMerchantImage: builder.mutation({
      query(body) {
        return {
          url: `${userManagement.userController.downloadMerchantImage}/${body.merchantId}`,
          method: "GET",
          headers: { Authorization: `Bearer ${body.token}` }
        };
      }
    }),
  })
});

export const {
  useAddUserMutation,
  useFindUserByIdMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetUsersMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useUploadUsersMutation,
  useFindUsernameMutation,
  useUploadProfileImageMutation,
  useDownloadProfileImageMutation,
  useUploadMerchantImageMutation,
  useDownloadMerchantImageMutation
} = usersService;
