import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { dataServiceBaseUrl, dataService } from '../endpoints';
// Define a service using a base URL and expected endpoints
export const allDataRecordsService = createApi({
    reducerPath: 'allDataRecordsService',
    baseQuery: fetchBaseQuery({ baseUrl: dataServiceBaseUrl }),
    tagTypes: ['DataRecords'],
    endpoints: (builder) => ({
        getAllDataRecords: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.dataRecordController.getAllDataRecords}`,
                    method: 'GET',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                }
            }
        }),
        getAllDataRecordsWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.dataRecordController.getAllDataRecordsWithSearch}`,
                    method: 'POST',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateDataRecord: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.dataRecordController.updateDataRecord}/${body.id}`,
                    method: "PUT",
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        deleteDataRecordById: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.dataRecordController.deleteDataRecordById}/${body.id}`,
                    method: "DELETE",
                };
            },
        }),
        getDataRecordById: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.dataRecordController.getDataRecordById}/${body.id}`,
                    method: "GET",
                    // headers: { Authorization: `Bearer ${body.token}`},
                };
            },
        }),
        saveDataRecord: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.dataRecordController.saveDataRecord}`,
                    method: "POST",
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        getAllDataRecordsWithPagination: builder.mutation({
            query(body){
                return {
                    url: `${dataService.dataRecordController.getDataRecordsWithPagination}?page=${body.page}&size=${body.size}&sort=${body.sort}`,
                    method: 'GET',
                }
            }
        })
    }),
});
export const {
    useGetAllDataRecordsMutation,
    useDeleteDataRecordByIdMutation,
    useGetDataRecordByIdMutation,
    useSaveDataRecordMutation,
    useUpdateDataRecordMutation,
    useGetAllDataRecordsWithPaginationMutation,
    useGetAllDataRecordsWithSearchMutation
} = allDataRecordsService;