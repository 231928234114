import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface permissionState {
  totalRecords: number | null;
  totalPage: number | null;
  currentPage: number | null;
  data: [] | null | any;
}

const initialState: permissionState = {
  data: null,
  totalPage: null,
  currentPage: null,
  totalRecords: null,
};

export const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    setPermissions: (state: any, action) => {
      state.data = action.payload.data;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPermissions, reset } = permissionSlice.actions;
export const getAllPermissions = (state: RootState) => state.permission.data;
export default permissionSlice.reducer;
