import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface AllDataPipelineCategoriesState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
}
const initialState: AllDataPipelineCategoriesState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
};
export const   allDataPipelinesCategoriesSlice = createSlice({
  name: 'allDataPipelinesCategories',
  initialState,
  reducers: {
    setAllDataPipelinesCategories: (state, action) => {
      state.data = action.payload.data.content;
      state.totalRecords = action.payload.data.totalElements;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.number;
    },
    setAllDataPipelinesCategoriesBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllDataPipelinesCategories,setAllDataPipelinesCategoriesBySearch,reset } = allDataPipelinesCategoriesSlice.actions;
export const getAllDataPipelinesCategoriesDetails = (state: RootState) => state.allDataPipelinesCategories.data;
export const getAllDataPipelinesCategoriesDetailsBySearch = (state: RootState) => state.allDataPipelinesCategories.data;
export const getDataPipelineCategoryTotalRecords=(state:RootState)=>state.allDataPipelinesCategories.totalRecords;
export default allDataPipelinesCategoriesSlice.reducer;