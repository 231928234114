import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface AuditLogState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
}
const initialState: AuditLogState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
};
export const   auditLogSlice = createSlice({
  name: 'auditLog',
  initialState,
  reducers: {
    setAuditLog: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAuditLog,reset } = auditLogSlice.actions;
export const getAuditLogDetails = (state: RootState) => state.auditLog.data;
export const getAuditLogTotalRecords = (state: RootState) => state.auditLog.totalRecords;
export default auditLogSlice.reducer;