import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';

type Props = {};

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const date = new Date();
  const dayOfDate = format(new Date(date), 'dd');
  const monthName = format(date, 'LLL');

  useEffect(() => {
    localStorage.setItem("routeTo", "privacy-policy");
  }, []);

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
          <div className="container-fluid">
            <a className="navbar-brand" onClick={() => navigate("/home")}>
              <img
                src={require("../../assets/logo-blue.png")}
                alt="Bootstrap"
                width="100"
                height="40"
              />
            </a>
          </div>
        </nav>
      </header>

      <div className="px-5">
        <div className="row pb-5 align-items-center">
          <div className="flex-lg-row main align-items-center d-flex w-100">
            <div className="bg-cm-light pt-5 col-lg-12 pr-lg-5 mb-5 mb-md-0">
              {/* about us */}
              <div className="about">
                <h1 className="text-cm-primary1 fw-bold">
                  Privacy Policy
                </h1>
                <p>
                  Thank you for using our services. By accessing and using this website or signing up for our services,
                  you acknowledge and agree to this Privacy Policy. This policy outlines the collection, storage, and use
                  of information, ensuring your privacy is protected. If you have any questions or concerns,
                  please contact us at info@capitoltunnels.ai
                </p>
                <p className="para-size text-cm-primary1 fw-bold">
                  Information Security
                </p>
                <p className="text-cm-primary1 fw-bold">
                  1. Encryption, Sensitive Information:
                </p>
                <p>
                  &#x2022; We employ encryption for data transfer and storage of sensitive information as per client requests.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  2. Regular Security Assessments, Penetration Testing:
                </p>
                <p>
                  &#x2022; We plan to conduct regular security assessments and penetration testing to ensure the robustness
                  of our security measures.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  3. Privacy Safeguards, Model Building:
                </p>
                <p>
                  &#x2022; We are committed to implementing privacy safeguards and building models that prioritize the
                  protection of personal information.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  4. Compliance, Data Transfer Regulations:
                </p>
                <p>
                  &#x2022; We comply with data transfer regulations and standards to safeguard your data during transfer.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  5. Clear, Accessible Language:
                </p>
                <p>
                  &#x2022; Our policies are written in clear, accessible, and simple language to ensure individuals are aware
                  of the risks, rules, safeguards, and rights related to personal data processing.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  6. Session Timeout, Logout Mechanisms:
                </p>
                <p>
                  &#x2022; We implement session timeout and logout mechanisms to protect your account and data.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  7. Data Retention, Minimization:
                </p>
                <p>
                  &#x2022; We determine appropriate periods for data retention and practice data minimization by limiting data collection.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  8. Rights to Rectify, Erase, Delete:
                </p>
                <p>
                  &#x2022; You have the right to rectify, erase, or delete personal data, and we respect and facilitate these rights.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  9. Access to Personal and Sensitive Data:
                </p>
                <p>
                  &#x2022; Personal data access is provided upon statutory or compliance request, and we ensure transparency in
                  the process.  Sensitive data handling is executed with utmost care and proper protocols.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  10. Clear Consent Language:
                </p>
                <p>
                  &#x2022; We use clear and unambiguous consent language for all data processing activities.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  11. Secure Photo, Video Sharing:
                </p>
                <p>
                  &#x2022; We do not store any video or photo presently in our system and will ensure secure mechanisms are
                  in place for photo and video sharing.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  12. Transparency in Data Processing:
                </p>
                <p>
                  &#x2022; We maintain transparency by providing clear information about our data processing practices.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  13. Purpose Limitation:
                </p>
                <p>
                  &#x2022; Data is collected for a specific, explicitly defined purpose, and we adhere to purpose limitation
                  principles.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  14. Prevent Data Breaches:
                </p>
                <p>
                  &#x2022; We implement measures to prevent data breaches and ensure the security of personal information.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  15. Storage Limitation:
                </p>
                <p>
                  &#x2022; Data is stored only for as long as necessary, adhering to storage limitation principles.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  16. Clear Cookie Policies:
                </p>
                <p>
                  &#x2022; Clear and transparent cookie policies are provided to users.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  17. Explainability, Transparency in AI Decision-Making:
                </p>
                <p>
                  &#x2022; We ensure explainability and transparency in AI decision-making processes.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  18. Privacy Protection and Accountability:
                </p>
                <p>
                  &#x2022; We implement measures to protect privacy and ensure accountability for compliance.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  19. Monitoring, Addressing Vulnerabilities:
                </p>
                <p>
                  &#x2022; We continuously monitor and address vulnerabilities to enhance our security posture.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  20. Withdrawal of Consent:
                </p>
                <p>
                  &#x2022; You have the ability to withdraw or remove consent at any time.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  21. Secure Coding, Protection Against OWASP Top 10:
                </p>
                <p>
                  &#x2022; We keep exploring secure coding practices, regular code reviews, and protection against OWASP Top 10
                  vulnerabilities, which are integral to our development process.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  22. Secure Session Handling, Authentication:
                </p>
                <p>
                  &#x2022; We prioritize secure session handling and authentication methods.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  23. Ensuring Data Privacy, Behavior Analysis:
                </p>
                <p>
                  &#x2022; Data privacy is ensured through behavior analysis and proactive measures.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  24. Encryption for Data Transit and Rest:
                </p>
                <p>
                  &#x2022; We rely on cloud services for encryption for data transit and rest to protect your information.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  25. Incident Response Plan:
                </p>
                <p>
                  &#x2022; The cloud services have an incident response plan in place to address cyber threats and provide
                  notification in case of an unlawful breach.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  26. Secure Communication Channels, Message Encryption:
                </p>
                <p>
                  &#x2022; Communication channels are secured, and messages are encrypted for additional protection.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  27. Handling Sensitive Data:
                </p>
                <p>
                  &#x2022; Sensitive data is handled with utmost care, and strict protocols are followed.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  28. Regular Testing, Validation of AI Models:
                </p>
                <p>
                  &#x2022; Regular testing and validation are conducted for AI models to ensure accuracy and security.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  29. Encryption Key Management, Access Controls:
                </p>
                <p>
                  &#x2022; Proper encryption key management and access controls are implemented wherever applicable.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  30. Rights to Restrict, Prohibit, Object:
                </p>
                <p>
                  &#x2022; You have the right to restrict, prohibit, or object to data processing.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  31. Lawful Processing:
                </p>
                <p>
                  &#x2022; Data is processed in accordance with legal requirements.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  32. Bias Detection, Mitigation in AI Algorithms:
                </p>
                <p>
                  &#x2022; We actively detect and mitigate bias in AI algorithms.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  33. Protecting Biometric, Health, Genetic, Financial Data:
                </p>
                <p>
                  &#x2022; Special measures are taken to protect biometric, health, genetic, and financial data.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  34. Data Accuracy:
                </p>
                <p>
                  &#x2022; Steps are taken to ensure the accuracy of data through validation processes.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  35. Use of Encryption in Data Transfer:
                </p>
                <p>
                  &#x2022; Cloud services may use encryption for secure data transfer.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  36. Security Measures for Encrypted Data:
                </p>
                <p>
                  &#x2022; Stringent security measures are applied to protect encrypted personal data.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  37. Mechanism for Obtaining, Managing Cookie Consent:
                </p>
                <p>
                  &#x2022; A clear mechanism is in place for obtaining and managing cookie consent.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  38. Respecting Individual Liberty, Autonomy:
                </p>
                <p>
                  &#x2022; We respect individual liberty and autonomy in our data processing practices.
                </p>
                <p className="text-cm-primary1 fw-bold">
                  39. Data Collection for Specific Purpose:
                </p>
                <p>
                  &#x2022; Data is collected for a specific, explicitly defined purpose, and we adhere to purpose limitation principles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="text-align-center">
        <p className="text-cm-dark fs-6">
          Capitol Tunnels.ai &#169; 2023
        </p>
      </footer>
    </>
  );
};
export default PrivacyPolicy;
