import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { dataServiceBaseUrl, dataService } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const allDataPipelinesService = createApi({
    reducerPath: 'allDataPipelinesService',
    baseQuery: fetchBaseQuery({ baseUrl: dataServiceBaseUrl }),
    tagTypes: ['DataPipelines'],
    endpoints: (builder) => ({
        getAllDataPipelines: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.dataPipelineController.getAllDataPipelines}?page=${body.page}&size=${body.size}&sort=${body.sort}`,
                    method: 'GET',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        getAllDataPipelinesWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.dataPipelineController.getAllDataPipelinesWithSearch}`,
                    method: 'POST',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        getAllDataPipelinesById: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.dataPipelineController.getDataPipelineById}/${body.id}`,
                    method: 'GET',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                }
            }
        }),
        saveDataPipeline: builder.mutation({
            query(body){
                return {
                    url: `${dataService.dataPipelineController.saveDataPipeline}`,
                    method: 'POST',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateDataPipeline: builder.mutation({
            query(body){
                return {
                    url: `${dataService.dataPipelineController.updateDataPipeline}/${body.payload.id}`,
                    method: 'PUT',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteDataPipelineById: builder.mutation({
            query(body){
                return {
                    url: `${dataService.dataPipelineController.deleteDataPipelineById}/${body.id}`,
                    method: 'DELETE',
                    // headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getCountAndPipelineCategoryName: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.dataPipelineController.getCountAndPipelineCategoryName}`,
                    method: "GET",
                    // headers: { Authorization: `Bearer ${body.token}`},
                };
            },
        }),
    })
  });
  export const {
   useGetAllDataPipelinesMutation,
   useSaveDataPipelineMutation,
   useUpdateDataPipelineMutation,
   useDeleteDataPipelineByIdMutation,
   useGetAllDataPipelinesByIdMutation,
   useGetAllDataPipelinesWithSearchMutation,
   useGetCountAndPipelineCategoryNameMutation
  } = allDataPipelinesService;