
type Props = {};

const PredictionModel = (props: Props) => {
  return (
    <div className="mt-4 overflow-y-auto">
      {/* Inlet */}
      <div className="d-flex align-items-center justify-content-between">
        <h4>PredictionModel</h4>
      </div>
    </div>
  );
};

export default PredictionModel;
