import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import "./auth.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import "./style.css";
import "../../assets/icons/mission.png";
import "../../assets/icons/vision.png";
import "../../assets/logo-blue.png";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { Typography } from "antd";

type Props = {
  setShowDataModal?: any;
  showDataModal?: any;
};

const { Link } = Typography;

const TestYourProject = (props: Props) => {
  // validation...........
  const testYourDataSchema = Yup.object().shape({
    startUp: Yup.string().required("Please enter First name!"),
    phone: Yup.string()
      // .required('Field is required')
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .min(10, "Contact Number must be 10 digits")
      .max(10, "Contact Number must be 10 digits")
      .trim(),

    email: Yup.string().required("Please enter Email Address!"),
  });

  //states
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDataSubmit = async (values: any) => {
    const payload = {
      startUp: values.startUp,
      email: values.email,
      phone: values.phone,
      policy: values.policy,
      data: values.data,
      algo: values.algo,
    };
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isShown, setIsSHown] = useState(false);

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleClose = () => props.setShowDataModal(false);

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="signup-modal"
        show={props.showDataModal}
        onHide={handleClose}
        className="login"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="text-align-center">Test your project for Non Compliance</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              startUp: "",
              email: "",
              phone: "",
              policy: "",
              data: "",
              algo: "",
            }}
            validationSchema={testYourDataSchema}
            onSubmit={handleDataSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="d-flex flex-column rounded-3 overflow-hidden px-5 mb-3">
                  <div className="d-flex">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="mb-1">Start Up</label>
                        <input
                          type="text"
                          value={values.startUp}
                          name="startUp"
                          onChange={handleChange}
                          className={`form-control form-textbox bg-secondary-light border-secondary-light ${
                            errors.startUp ? "is-invalid" : ""
                          }`}
                          id="startUp"
                          aria-describedby="firstName"
                          //   placeholder="Start Up"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="mb-1">Email</label>
                        <input
                          type="email"
                          value={values.email}
                          name="email"
                          onChange={handleChange}
                          className={`form-control form-textbox bg-secondary-light border-secondary-light ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          id="email"
                          aria-describedby="email"
                          //   placeholder="Email"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="mb-1">Phone</label>
                        <input
                          type="text"
                          value={values.phone}
                          name="phone"
                          onChange={handleChange}
                          className={`form-control form-textbox bg-secondary-light border-secondary-light ${
                            errors.phone ? "is-invalid" : ""
                          }`}
                          id="phone"
                          aria-describedby="phone"
                          //   placeholder="Phone"
                        />
                      </div>

                      <div className="mb-3">
                        <label className="mb-1">Accept Policy PDF File</label>
                        <input
                          aria-label=".form-control-sm example"
                          className="form-control form-control-sm w-100"
                          type="file"
                          id="avatar"
                          name="policy"
                          accept=".pdf, .txt"
                          onChange={handleChange}
                          value={values.policy}
                          //   placeholder="Accept Policy PDF File"
                          // required
                        />
                      </div>
                      <div className="mb-3">
                        <label className="mb-1">Accept Data File</label>
                        <input
                          aria-label=".form-control-sm example"
                          className="form-control form-control-sm w-100"
                          type="file"
                          id="avatar"
                          name="data"
                          accept=".pdf, .txt"
                          onChange={handleChange}
                          value={values.data}
                          //   placeholder="Accept Data File"
                          // required
                        />
                      </div>
                      <div className="mb-3">
                        <label className="mb-1">
                          Accept Algorithm Test File
                        </label>
                        <input
                          aria-label=".form-control-sm example"
                          className="form-control form-control-sm w-100"
                          type="file"
                          id="avatar"
                          name="algo"
                          accept=".pdf, .txt"
                          onChange={handleChange}
                          value={values.algo}
                          //   placeholder="Accept Algorithm Test File"
                          // required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                    >
                      {" "}
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm btn-block mt-3 mx-2 btn-width"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default TestYourProject;
