import { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  BiChevronLeft,
  BiChevronRight,
  BiChevronDown,
  BiBell
} from "react-icons/bi";
import "./search.scss";
import { useAppSelector } from "../redux-toolkit/hooks";
import { getAuthData } from "../redux-toolkit/slices/authSlice";

import { Button } from "react-bootstrap";

type Props = {};

const UnAuthorizedLayout = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);

  const currentUserData = useAppSelector(getAuthData);
  const currentUserName: any = localStorage.getItem("name");

  const handleMenuToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLogOut = () => {
    navigate("/logout");
  };

  return (
    <div className="cm-sidebar-layout d-flex vh-100">
      <div
        className="flex-fill  bg-cm-light-primary  vh-100 d-flex flex-column"
        style={{ width: "80%" }}
      >
        <div className="cm-header sticky-top bg-cm-light-primary1 d-flex align-items-center justify-content-between p-3">
          <img
            src={require("../assets/logo-blue.png")}
            height={40}
            width={100}
            alt=""
          />
        </div>
        <div className="cm-content-wrapper p-3 px-md-4 px-3 h-100 flex-fill">
          {/* All the pages will be rendered here using Outlet component. */}
          {/* <Outlet /> */}
          <div className="d-flex flex-column h-100 justify-content-center align-items-center">
            <h3>You don't have permission to access this page</h3>
            <Button
              variant="primary"
              className="d-flex align-items-center me-0"
              onClick={handleLogOut}
            >
              Sign out
            </Button>
          </div>
          {/* <p>Your current account {} can't access this page, or the page doesn't exist.</p> */}
        </div>

      </div>
    </div>
  );
};

export default UnAuthorizedLayout;
